import { DollarOutlined, BankOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getTickerInfos } from "../services/ticker.service";
import { isMobile } from '../utils/utils';
import { getTickerDemoInfos } from '../services/demo.service';
import BarChart from './charts/BarChart';


// SHARED VARIABLES //

const IS_MOBILE = isMobile()


// PARAMETERS //



/**
 * 
 * @param {*} props: Data is either provided as parameter or requested via API
 * @returns 
 */
const FinancialChart = (props) => {

    var currentTicker = props.ticker
    if (!currentTicker)
        currentTicker = props.tkrInfos?.ticker

    const { getAccessTokenSilently } = useAuth0();

    const [incomeXAxis, setIncomeXAxis] = useState([]);
    const [incomeTotalRevenue, setIncomeTotalRevenue] = useState([]);
    const [incomeNetIncome, setIncomeNetIncome] = useState([]);

    const [balanceXAxis, setBalanceXAxis] = useState([]);
    const [balanceTotalAssets, setBalanceTotalAssets] = useState([]);
    const [balanceTotalLiabilities, setBalanceTotalLiabilities] = useState([]);

    useEffect(() => {
        currentTicker = props.ticker
        if (!currentTicker)
            currentTicker = props.tkrInfos?.ticker

        const fetchData = async () => {

            let tkrInfos = props.tkrInfos
            if (!tkrInfos) {
                if (props.isDemo) {
                    const { data, error } = await getTickerDemoInfos(currentTicker);
                    tkrInfos = data
                } else {
                    const accessToken = await getAccessTokenSilently();
                    const { data, error } = await getTickerInfos(accessToken, currentTicker);
                    tkrInfos = data
                }
            }
            // console.log("Object:" + JSON.stringify(data));

            // Crypto garde!
            if (tkrInfos.assetType === "CRYPTO")
                return;


            // INCOME STATEMENTS //

            let income = tkrInfos.incomeStatement.quarter

            // Annual for mobile
            if (IS_MOBILE)
                income = tkrInfos.incomeStatement.annual

            setIncomeXAxis(Object.keys(income))
            setIncomeTotalRevenue([
                {
                    name: 'Total Revenue',
                    data: Object.entries(income).map(([k, v]) => v.totalRevenue)
                },
            ])
            setIncomeNetIncome([
                {
                    name: 'Net Income',
                    data: Object.entries(income).map(([k, v]) => v.netIncome)
                }
            ])
            // const incomeGrossProfit = Object.entries(income).map(([k, v]) => v.grossProfit)
            // const incomeEbit = Object.entries(income).map(([k, v]) => v.ebit)
            // {
            //     name: 'Gross Profit',
            //     data: incomeGrossProfit
            // },
            // {
            //     name: 'EBIT',
            //     data: incomeEbit
            // },


            // BALANCE SHEET //

            let balance = tkrInfos.balanceSheet.quarter
            
            // Annual for mobile
            if (IS_MOBILE)
                balance = tkrInfos.balanceSheet.annual

            setBalanceXAxis(Object.keys(balance))
            setBalanceTotalAssets([
                {
                    name: 'Total Assets',
                    data: Object.entries(balance).map(([k, v]) => v.totalAssets)
                },
            ])
            setBalanceTotalLiabilities([
                {
                    name: 'Total Liabilities',
                    data: Object.entries(balance).map(([k, v]) => v.totalLiabilities)
                },
            ])
        }
        fetchData()
    }, [props.ticker, props.tkrInfos])


    return (
        <Row id='fundamentals' gutter={[{ xs: 0, md: 34 }, 0]}>
            <Col xs={24} md={12}>
                <BarChart
                    title={<Space><DollarOutlined />Total Revenue</Space>}
                    height='300px'
                    series={incomeTotalRevenue}
                    xAxis={incomeXAxis}
                    color={0}
                    xAxisFormat={IS_MOBILE ? 'Y' : 'Q'}
                    currency
                    ttFormat />
            </Col>
            <Col xs={24} md={12}>
                <BarChart
                    title={<Space><DollarOutlined />Net Income</Space>}
                    height='300px'
                    series={incomeNetIncome}
                    xAxis={incomeXAxis}
                    color={2}
                    xAxisFormat={IS_MOBILE ? 'Y' : 'Q'}
                    currency
                    ttFormat />
            </Col>
            <Col xs={24} md={12}>
                <BarChart
                    title={<Space><BankOutlined />Total Assets</Space>}
                    height='300px'
                    series={balanceTotalAssets}
                    xAxis={balanceXAxis}
                    color={1}
                    xAxisFormat={IS_MOBILE ? 'Y' : 'Q'}
                    currency
                    ttFormat />
            </Col>
            <Col xs={24} md={12}>
                <BarChart
                    title={<Space><BankOutlined />Total Liabilities</Space>}
                    height='300px'
                    series={balanceTotalLiabilities}
                    xAxis={balanceXAxis}
                    color={3}
                    xAxisFormat={IS_MOBILE ? 'Y' : 'Q'}
                    currency
                    ttFormat />
            </Col>
        </Row>
    )
}

export default FinancialChart;
