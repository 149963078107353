import { Button } from 'antd';

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/watchlist?login=true",
            },
            authorizationParams: {
                prompt: "login",
            },
        });
    };

    return (
        <Button name='login' type='primary' onClick={handleLogin}>
            Log In
        </Button>
    );
};
