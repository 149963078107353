import '../styles/Home.css';

import { BellOutlined } from '@ant-design/icons';
import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';
import { Col, Image, Layout, Row, Typography } from 'antd';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { SignupButton } from "../components/buttons/signup-button";
import { IconSignalBuyL2, IconSignalSellL2 } from '../components/Icons';
import { TagAITagging } from '../components/Tags';
import { isMobile } from '../utils/utils';
import Arrival2 from './landings/pages/Arrival2';
import Enrollement1 from './landings/pages/Enrollement1';


// SHARED VARIABLES //
const { Title, Text } = Typography
const IS_MOBILE = isMobile()


const Home = () => {

    // OBSERVER //

    const [refP1, inViewP1] = useInView({
        triggerOnce: false, // Only trigger once when entering viewport

        // Percentage of the HTML element visibility to trigger the animation
        threshold: IS_MOBILE ? 0.3 : 0.5,
    })

    const [refP2, inViewP2] = useInView({
        triggerOnce: false,
        threshold: IS_MOBILE ? 0.3 : 0.5,
    })

    const [refFinancialCharts, inViewFinancialCharts] = useInView({
        triggerOnce: false,
        threshold: IS_MOBILE ? 0.3 : 0.40,
    })

    const [refScreener, inViewScreener] = useInView({
        triggerOnce: false,
        threshold: IS_MOBILE ? 0.3 : 0.50,
    })

    const [refWatchlist, inViewWatchlist] = useInView({
        triggerOnce: false,
        threshold: IS_MOBILE ? 0.3 : 0.40,
    })

    const [refP3, inViewP3] = useInView({
        triggerOnce: false,
        threshold: IS_MOBILE ? 0.3 : 0.6,
    })

    const [refP4, inViewP4] = useInView({
        triggerOnce: false,
        threshold: IS_MOBILE ? 0.3 : 0.54,
    })

    // SPRING EFFECTS //

    const springCandleChart = useSpring({
        config: { duration: 1000 },
        from: { opacity: inViewP1 ? 0 : 1, },
        to: {
            opacity: inViewP1 ? 1 : 0, // Set opacity based on visibility
            //transform: inView ? 'translateX(0)' : 'translateX(100px)', // Set transform based on visibility
        },
    })

    // Financial Charts
    const springFinancialCharts = useSpring({
        config: { duration: 1000 },
        from: { opacity: inViewFinancialCharts ? 0 : 1, },
        to: {
            opacity: inViewFinancialCharts ? 1 : 0,
        },
    })

    // Screener
    const springScreener = useSpring({
        config: { duration: 1000 },
        from: { opacity: inViewScreener ? 0 : 1, },
        to: {
            opacity: inViewScreener ? 1 : 0,
        },
    })

    // Watchlist
    const springWatchlist = useSpring({
        config: { duration: 1000 },
        from: { opacity: inViewWatchlist ? 0 : 1, },
        to: {
            opacity: inViewWatchlist ? 1 : 0,
        },
    })

    // Financial Graph
    const springFinancial = useSpring({
        config: { duration: 1000 },
        from: { opacity: inViewP2 ? 0 : 1, },
        to: {
            opacity: inViewP2 ? 1 : 0,
        },
    })

    // Index View
    const springIdxView = useSpring({
        config: { duration: 1000 },
        from: { opacity: inViewP3 ? 0 : 1, },
        to: {
            opacity: inViewP3 ? 1 : 0,
        },
    })

    // Last
    const springRefLove = useSpringRef()
    const springLove = useSpring({
        ref: springRefLove,
        config: { duration: 1000 },
        from: { opacity: inViewP4 ? 0 : 1, },
        to: {
            opacity: inViewP4 ? 1 : 0,
        },
    })
    const springRefGetStarted = useSpringRef()
    const springGetStarted = useSpring({
        ref: springRefGetStarted,
        config: { duration: 900 },
        from: {
            opacity: inViewP4 ? 0 : 1,
            transform: 'translateX(-30)',
        },
        to: {
            opacity: inViewP4 ? 1 : 0,
            transform: inViewP4 ? 'translateX(0)' : 'translateX(-30px)'
        },
    })
    useChain([springRefLove, springRefGetStarted])


    const financialGraphVideoRef = useRef(null);
    useEffect(() => {
        const video = financialGraphVideoRef.current;
        video.play()

        let interval = null
        if (inViewP2) {
            interval = setInterval(() => {
                video.currentTime = 0   // Restart the video from the beginning
                video.play();           // Start playing the video
            }, 5000)                    // Repeat every 5 seconds
        }

        return () => {
            clearInterval(interval) // Clean up the interval when the component unmounts > Can cause memory leak
        };
    }, [inViewP2])


    // lg is the breack point for iPad Pro 11 landscape
    const gutter = { xs: 0, sm: 0, md: 30, lg: 80, xxl: 90 }

    return (
        <Layout>

            <Arrival2 />

            <Enrollement1 />

            <div className="page page-grey" style={{ borderBottom: '1px solid rgba(232, 232, 232, 0.9)' }} ref={refScreener}>
                <animated.div className="landing-content" style={{ ...springScreener }}>
                    <Row gutter={gutter}>
                        <Col xs={24} md={8}>
                            <div className="text-container">
                                <Title level={1} className='page-title'>Find Opportunities In Few Clicks</Title>
                                <Text className='page-description'>
                                    The <b>AI Screener</b> helps you find the <b>best market opportunities</b> in just few steps with our <b>AI Signals</b> &nbsp;
                                    <IconSignalBuyL2 /> <IconSignalSellL2 /> and <TagAITagging style={{ marginRight: '0' }} /> filters.<br />
                                    Our AI Model continuously analyzes all assets, allowing you to focus only on the ones that matter.
                                </Text>
                                {!IS_MOBILE &&
                                    <div>
                                        <SignupButton className="landing-signup" type='primary' label='Explore AI Screener Free' />
                                    </div>
                                }
                            </div>
                        </Col>
                        <Col xs={24} md={16} align='middle'>
                            <div className='img-background'>
                                <Image src='/landing_screener.png' alt='Screener' preview={false} style={{ padding: '0.1em' }} />
                            </div>
                        </Col>
                    </Row>
                </animated.div>
            </div>

            <div className="page" ref={refP1}>
                <animated.div className="landing-content" style={{ ...springCandleChart, }}>
                    <Row gutter={gutter}>
                        <Col xs={24} md={16}>
                            <div className='img-background'>
                                <Image src='/landing_candlestick_chart.png' alt='Candlestick Chart AI Signals' preview={false} />
                            </div>
                        </Col>
                        <Col xs={24} md={8}>
                            <div className="text-container">
                                <Title level={1} className='page-title'>Candlestick Chart With AI Insights</Title>
                                <Text className='page-description'>
                                    Discover the power of our cutting-edge <b>Multi-Model AI</b>.
                                    It intelligently analyzes market charts, pinpointing<br />
                                    <IconSignalBuyL2 /><b>Buying</b> and &nbsp;<IconSignalSellL2 /><b>Selling Momentum</b> right on the <b>interactive chart</b>.<br />
                                    Gain an unrivaled perspective on the lifecycle of your assets and elevate your trading game with our <b>AI-driven insights!</b>
                                </Text>
                                {!IS_MOBILE &&
                                    <div>
                                        <SignupButton className="landing-signup" type='primary' label='Start Analyzing Free' />
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </animated.div>
            </div>

            <div className="page page-grey" ref={refWatchlist}>
                <animated.div className="landing-content" style={{ ...springWatchlist, }}>
                    <Row gutter={gutter}>
                        <Col xs={24} md={8}>
                            <div className="text-container">
                                <Title level={1} className='page-title'>Instant Watchlist Insights</Title>
                                <Text className='page-description'>
                                    The <b>AI Model</b> highlight symbols with interesting<br />
                                    &nbsp;<IconSignalBuyL2 /><b>Buying</b> and &nbsp;<IconSignalSellL2 /><b>Selling Momentum</b>.
                                    enabling you to identify assets of interest without wasting time.<br />
                                    Activate <b>alerts</b> <BellOutlined style={{ fontSize: '14px' }} /> for any symbol to be notified as soon as a signal is triggered.
                                </Text>
                                {!IS_MOBILE &&
                                    <div>
                                        <SignupButton className="landing-signup" type='primary' label='Create Your Watchlist Free' />
                                    </div>
                                }
                            </div>
                        </Col>
                        <Col xs={24} md={16}>
                            <div className='img-background'>
                                <Image src='/landing_watchlist.png' alt='Watchlist' preview={false} style={{ padding: '0.1em' }} />
                            </div>
                        </Col>
                    </Row>
                </animated.div>
            </div>

            <div className="page" ref={refFinancialCharts}>
                <animated.div className="landing-content" style={{ ...springFinancialCharts, }}>
                    <Row gutter={gutter}>
                        <Col xs={24} md={16}>
                            <div className='img-background'>
                                <Image src='/landing_financial_charts.png' alt='Financials Charts' preview={false} />
                            </div>
                        </Col>
                        <Col xs={24} md={8}>
                            <div className="text-container">
                                <Title level={1} className='page-title'>Beautiful Financial Charts</Title>
                                <Text className='page-description'>
                                    Explore visually <b>stunning charts</b> that illuminate company <b>financial statements</b> and <b>fundamentals</b>,
                                    making it easier than ever to spot <b>key metrics</b> and <b>trends</b>.
                                    With our app, you'll make <b>informed investment decisions</b> with <b>confidence</b> and data visualization that makes sense.
                                </Text>
                                {!IS_MOBILE &&
                                    <div>
                                        <SignupButton className="landing-signup" type='primary' label='Explore Financial Charts Free' />
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </animated.div>
            </div>

            <div className="page page-grey" ref={refP3}>
                <animated.div className="landing-content" style={{ ...springIdxView, }}>
                    <Row gutter={gutter}>
                        <Col xs={24} md={8}>
                            <div className="text-container">
                                <Title level={1} className='page-title'>Market At a Glance</Title>
                                <Text className='page-description'>
                                    Get a <b>panoramic overview</b> of the market with our Stock Index View.
                                    See the <b>prevailing trends</b> and <b>valuable insights</b> at a <b>single glance</b>,
                                    giving you the knowledge you need to stay <b>ahead</b>.
                                </Text>
                                {!IS_MOBILE &&
                                    <div>
                                        <SignupButton className="landing-signup" type='primary' label='Explore Market At A Glance Free' />
                                    </div>
                                }
                            </div>
                        </Col>
                        <Col xs={24} md={16}>
                            <div className='img-background'>
                                <Image src='/landing_market_overview.png' alt='Stock Index View' preview={false} style={{ padding: '0.1em' }} />
                            </div>
                        </Col>
                    </Row>
                </animated.div>
            </div>

            {/* <Testimonial /> */}

            <div className="page" ref={refP2}>
                <animated.div className="landing-content" style={{ ...springFinancial, }}>
                    <Row gutter={gutter}>
                        <Col sm={24} md={15}>
                            <div className='img-background'>
                                <video style={{ backgroundColor: '#fff', padding: '0.6em', borderRadius: '10px' }} ref={financialGraphVideoRef} src='/financial_graph.mp4' playsInline muted />
                            </div>
                        </Col>
                        <Col sm={24} md={9}>
                            <div className="text-container">
                                <Title level={1} className='page-title'>The Financial Dependency Graph</Title>
                                <Text className='page-description'>
                                    Get a comprehensive view of the <b>interrelationships among financial companies</b>.
                                    Our Financial Dependency Graph provides a visual map of these connections,
                                    empowering you to <b>anticipate</b> potential ripple effects and make <b>proactive decisions</b>.
                                </Text>
                                {!IS_MOBILE &&
                                    <div>
                                        <SignupButton className="landing-signup" type='primary' label='Access To Financial Graph Free' />
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </animated.div>
            </div>

            <div className="page4" ref={refP4}>
                <div className="landing-content">
                    <animated.div className="value-title" style={{ ...springLove, }}>
                        Trade Smarter, Not Harder...
                    </animated.div>
                    <animated.div style={{ ...springGetStarted, }}>
                        <SignupButton className="value-signup" label='Get Started Now' />
                    </animated.div>
                </div>
            </div>
        </Layout>
    )
}

export default Home;