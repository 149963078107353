import { FundOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import { Space } from 'antd';
import React, { useContext, useEffect, useState } from "react";
import { getTickersInfos } from '../services/ticker.service';
import { IconInfoChartGainsByIndustry } from './Icons';
import UserContext from './UserContext';
import PieChart from './charts/PieChart';



const PortfolioIndustryChart = () => {

	const { getAccessTokenSilently } = useAuth0()
	const { userContext } = useContext(UserContext)

	const [pieData, setPieData] = useState([])

	useEffect(() => {

		const fetchData = async () => {
			if (userContext != null && userContext.stockTradingBook.length > 0) {

				const stockTradingBook = userContext.stockTradingBook

				// Use a Set to store unique symbols
				const uniqueSymbols = new Set();
				stockTradingBook.forEach((item) => {
					uniqueSymbols.add(item.symbol)
				})

				const accessToken = await getAccessTokenSilently();
				const resp = await getTickersInfos(accessToken, Array.from(uniqueSymbols))
				const tickersInfosList = resp.data

				// Map to aggregate gains/losses per industry
				const industryData = {}

				// Iterate through unique symbols
				uniqueSymbols.forEach((symbol) => {
					// Get industry for the symbol
					const industry = tickersInfosList[symbol]?.industry || "Unknown";

					// Calculate total buy and sell for the symbol
					const totalBuy = stockTradingBook.reduce((sum, order) => {
						if (order.symbol === symbol && order.actionType === 'BUY' && order.shares !== order.remainingShares) {
							return sum + (order.shares - order.remainingShares) * order.price + order.fee
						}
						return sum
					}, 0)

					const totalSell = stockTradingBook.reduce((sum, order) => {
						if (order.symbol === symbol && order.actionType === 'SELL') {
							return sum + order.shares * order.price - order.fee + order.totalDividendsPaid
						}
						return sum
					}, 0)

					// Calculate net gain/loss for the symbol
					const netGain = totalSell - totalBuy;

					if (netGain > 0) {
						// Aggregate data by industry
						if (!industryData[industry]) {
							industryData[industry] = 0
						}
						industryData[industry] += netGain
					}
				})

				// Create pie chart data
				const data = Object.entries(industryData).map(([industry, value]) => ({
					value,
					name: industry,
				}))

				setPieData(data)
			}
		}
		fetchData()
	}, [userContext])


	return (
		<PieChart
			title={<Space><FundOutlined />Gains By Industry</Space>}
			height='500px'
			data={pieData}
			filled
			legend={false}
			currency
			extra={<IconInfoChartGainsByIndustry />} />
	)
}

export default PortfolioIndustryChart;