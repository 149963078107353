import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EditOutlined, EnterOutlined } from '@ant-design/icons';
import styles from '../../styles/inputs/EditableMultilineParagraph.module.css';
import { COLOR_PRIMARY } from '../../JTIConst';

export default function EditableMultilineParagraph({ onChange, text, ...props }) {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(text);


    useEffect(() => {
        setValue(text)
    }, [text])

    const handleBlur = () => {
        if (onChange) onChange(value)
        setEditing(false)
    }

    const handleKeyDown = (e) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
            if (onChange) onChange(value)
            setEditing(false)
        }
    }

    const handleChange = (e) => setValue(e.target.value)

    const handleEditClick = () => setEditing(true) // Start editing when icon is clicked

    if (editing) {
        return (
            <div className={styles['editing-container']} style={{ position: 'relative' }}>
                <textarea
                    rows={8}
                    className={styles['textarea']}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    autoFocus={true}
                />
                <div
                    className={styles['legend']}
                    style={{
                        position: 'absolute',
                        bottom: 8,
                        right: 8,
                        fontSize: 12,
                        color: '#bfbfbf',
                    }}
                >
                    Ctrl + <EnterOutlined style={{ fontSize: 12 }} />
                </div>
            </div>
        )
    }

    return (
        <Typography.Paragraph
            style={{
                whiteSpace: 'pre-wrap',
                cursor: 'pointer',
                alignItems: 'center',
                width: '100%',
            }}
            {...props}
        >
            {value}
            <EditOutlined
                style={{
                    marginLeft: 4, // Add space between text and icon
                    color: COLOR_PRIMARY, // Change icon color here
                    cursor: 'pointer',
                }}
                onClick={handleEditClick}  // Trigger edit on icon click
            />
        </Typography.Paragraph>
    );
}

EditableMultilineParagraph.propTypes = {
    onChange: PropTypes.func,
    text: PropTypes.string,
}

EditableMultilineParagraph.defaultProps = {
    text: '',
    onChange: null,
}