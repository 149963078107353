import { CloseOutlined, EditOutlined, LineChartOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Form, Image, Popconfirm, Space, Table, Tabs, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useContext, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import UserContext from '../components/UserContext';
import { COLOR_PRIMARY, DATE_DAY_FIRST, DATE_YEAR_FIRST, TOOLTIP_ENTER_DELAY } from '../JTIConst';
import { getTickersInfos, getTickersQuotes } from '../services/ticker.service';
import { EditableCellRow } from '../utils/CustomizeTable';
import { calculatePaidDividends, formatNumber, formatPriceChg, getGainLossColor, getLogoUrl, isMobile, navigateToTickerObject, pourcentage, tradingBookComputeGains, tradingBookGetUniqueSymbols } from '../utils/utils';
import { IconBuyOrder, IconEMA, IconLoading, IconMA, IconSellOrder, IconSignalSellL1, IconSignalSellL2 } from "./Icons";
import { TagAIHoldingStatusHold } from './Tags';


// PARAMETERS //
const TABLE_SCROLL_X = 730
const STYLE_RT_FONT_WEIGHT = 500
const REAL_TIME_INTERVAL = 6000     // Each X sec
const REAL_TIME_COUNT_OFF = 2      // Real time interval stop when counter reach x (no new quotes x times)


// SHARED VARIABLES //
const { Text } = Typography;
dayjs.extend(utc)
let rowKeyCount = 0
let intervalId = null

const TradingBook = ({ tradingBook, viewMode = true }) => {

    const [isLoading, setIsLoading] = useState(true)
    const { userContext, updateUserStockTradingBook } = useContext(UserContext);
    const { getAccessTokenSilently } = useAuth0();
    const [stockTradingBookData, setStockTradingBookData] = useState([]);
    const [tradingBookPagination, setTradingBookPagination] = useState({ current: 1, pageSize: 10 });
    const [holdingsData, setHoldingsData] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState('Holdings');
    const [form] = Form.useForm()
    const [editingKey, setEditingKey] = useState('');


    // FUNCTIONS //

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            ...record,
        })
        setEditingKey(record.key);
    }

    const cancel = () => {
        setEditingKey('')

        const row = stockTradingBookData.at(0);
        if (row?.newRow === true) {
            setStockTradingBookData(stockTradingBookData.filter((r) => r.newRow !== true))
            rowKeyCount--
        }
    }

    const save = async (key) => {

        // Safe guard
        if (viewMode)
            return

        try {
            const row = await form.validateFields();
            const newTradingBook = [...stockTradingBookData]
            const index = newTradingBook.findIndex((item) => key === item.key);

            // TODO : Check if new row and old row are different

            if (index > -1) {

                // Replace the edited row
                const item = newTradingBook[index];
                newTradingBook.splice(index, 1, { ...item, ...row })

                // Sort trading book descending
                newTradingBook.sort((a, b) => b.date - a.date)

                setStockTradingBookData(newTradingBook)

                // Move user to the new page where the row is inserted

                // Find the position of the new row
                const newRowIndex = newTradingBook.findIndex(r => r.key === key) + 1
                var newPage = Math.ceil(newRowIndex / tradingBookPagination.pageSize)

                console.log("newPage: " + newPage);

                // Update pagination
                setTradingBookPagination(prev => ({ ...prev, current: newPage }))

                setEditingKey('')

                // Trigger animation
                setAnimatedRow(key)
                springsRowApi.start({
                    to: { backgroundColor: 'white' },
                    from: { backgroundColor: COLOR_PRIMARY },
                    config: { duration: 800 },
                })

                // Re-compute trading book
                const accessToken = await getAccessTokenSilently()
                const resp = await getTickersInfos(accessToken, getUniqueSymbolsFromTdgBook(newTradingBook))
                tradingBookComputeGains(newTradingBook, resp.data)

                // Don't use key attribute (attribute not in JAVA model can be left, the controller will bypass them)
                updateUserStockTradingBook(newTradingBook.map(({ key, ...restProps }) => ({
                    ...restProps,
                    date: dayjs(restProps.date).format(DATE_YEAR_FIRST)
                })))

            } else {
                console.warn(`Editing row key not found: ${key}`);
                setEditingKey('')
            }
        } catch (errInfo) {
            console.log('Form validation failed:', errInfo);
        }
    }

    const handleDelete = async (key) => {

        // Safe guard
        if (viewMode)
            return

        const newData = stockTradingBookData.filter(item => item.key !== key);

        // Re-compute trading book
        const accessToken = await getAccessTokenSilently()
        const resp = await getTickersInfos(accessToken, getUniqueSymbolsFromTdgBook(newData))
        tradingBookComputeGains(newData, resp.data)

        setStockTradingBookData(newData);
        rowKeyCount--

        updateUserStockTradingBook(newData.map(({ key, ...restProps }) => ({
            ...restProps,
            date: dayjs(restProps.date).format(DATE_YEAR_FIRST)
        })))
    };

    // Add new row
    const handleAdd = () => {

        const newData = {
            key: rowKeyCount,
            symbol: '',
            date: dayjs(),
            actionType: 'BUY',
            shares: '',
            price: '',
            fee: '',
            newRow: true
        }

        // Move user to the 1st page
        setTradingBookPagination(prev => ({ ...prev, current: 1 }))

        form.setFieldsValue(newData)
        setStockTradingBookData([newData, ...stockTradingBookData]);
        setEditingKey(rowKeyCount);
        rowKeyCount++
    }

    // Manage real time price update
    var countOff = 1

    const updatePrices = async () => {

        const accessToken = await getAccessTokenSilently()
        const quotesResp = await getTickersQuotes(accessToken, getUserHoldingsSymbol(tradingBook))
        const quotes = quotesResp.data

        setHoldingsData((prevTableData) => {
            return prevTableData?.map(record => {
                const lastQuote = quotes?.[record.symbol]

                // If quote response is different than current, update last price and change
                if (lastQuote && lastQuote.close && lastQuote.close != record.lastPrice) {
                    countOff = 0

                    // Compute gain/loss
                    const gainLoss = computeHoldingsGainLoss(record.totalInvested, lastQuote.close * record.shares, record.totalDividendsPaid)

                    return {
                        ...record,
                        lastPrice: lastQuote.close,
                        ...gainLoss,
                        animate: true
                    }
                }

                // If quote response is same as current, keep the item as is
                return { ...record, animate: false }
            })
        })

        // Stop real time if counter is reach > clear interval
        if (countOff >= REAL_TIME_COUNT_OFF)
            clearInterval(intervalId)
        else
            countOff++
    }


    // SPRING ANIMATION //

    const [springs, api] = useSpring(() => ({
        config: { duration: 800 },
        from: { opacity: 0.4 },
        to: {
            opacity: 1,
        },
    }))
    const animateChanges = () => {
        api.start({
            from: { opacity: 0.4 },
            to: {
                opacity: 1,
            },
        })
    }

    const [animatedRow, setAnimatedRow] = useState(null);
    const [springsRow, springsRowApi] = useSpring(() => { });


    // TRADING BOOK ---------------------------------------------------------------------------------------------------------------------------------

    const tdgBkDefaultColumns = [
        {
            title: <span style={{ paddingLeft: '0.2em' }}>Symbol</span>,
            dataIndex: 'symbol',
            width: '9%',
            editable: true,
            type: 'symbol',
            render: (symbol) =>
                <Space align="center">
                    <Image src={getLogoUrl(symbol)} alt={symbol} width={16} preview={false} />
                    <Text>{symbol}</Text>
                </Space>
        },
        {
            title: 'Date',
            dataIndex: 'date',
            align: 'center',
            editable: true,
            type: 'date',
            render: (text, record) => { return dayjs(text).format(DATE_DAY_FIRST) },
        },
        {
            title: 'Action Type',
            dataIndex: 'actionType',
            align: 'center',
            editable: true,
            type: 'action',
            render: (text) => {
                if (text === 'BUY')
                    return isMobile() ? <IconBuyOrder /> : <><IconBuyOrder /> {text}</>
                else
                    return isMobile() ? <IconSellOrder /> : <><IconSellOrder /> {text}</>
            }
        },
        {
            title: 'Number Of Shares',
            dataIndex: 'shares',
            align: 'center',
            editable: true,
            type: 'numberAboveZero'
        },
        {
            title: 'Price',
            dataIndex: 'price',
            align: 'center',
            editable: true,
            type: 'numberAboveZero',
            render: (_, record) => '$ ' + formatNumber(record.price)
        },
        {
            title: 'Fee',
            dataIndex: 'fee',
            align: 'center',
            editable: true,
            type: 'numberNoNegative',
            render: (_, record) => '$ ' + formatNumber(record.fee)
        },
        {
            title: 'Total Transaction',
            dataIndex: 'totalInvested',
            width: '12%',
            align: 'center',
            render: (_, record) => {
                const total = (record.price * record.shares) + record.fee
                return '$ ' + formatNumber(total)
            },
        },
        {
            title: 'Dividends Paid',
            dataIndex: 'totalDividendsPaid',
            align: 'center',
            render: (_, record) => {
                if (record.totalDividendsPaid !== undefined)
                    return '$ ' + formatNumber(record.totalDividendsPaid)
                else
                    return '-'
            },
            // sorter: {
            //     compare: (a, b) => {
            //         // Manage undefined values
            //         const valueA = a.totalDividendsPaid !== undefined ? parseFloat(a.totalDividendsPaid) || 0 : 0;
            //         const valueB = b.totalDividendsPaid !== undefined ? parseFloat(b.totalDividendsPaid) || 0 : 0;
            //         return valueA - valueB;
            //     }
            // }
        },
        {
            title: 'Gain / Loss',
            dataIndex: 'gainLoss',
            width: '13%',
            align: 'center',
            render: (_, record) => {
                if (typeof record.gainLoss === 'number' && !Number.isNaN(record.gainLoss) && record.gainLoss !== Infinity) {
                    return (
                        <Text strong style={{ color: getGainLossColor(record.gainLoss) }}>
                            {renderGainLoss(record.gainLossAmount, record.gainLoss.toFixed(2))}
                        </Text>)

                } else if (record.gainLoss === Infinity)
                    return 'Insufficient buy shares'
                else
                    return '-'
            },
        },
        {
            title: '',
            dataIndex: 'action',
            align: 'center',
            render: (_, record) => {
                if (stockTradingBookData.length >= 1) {
                    return isEditing(record) ? (
                        <span>
                            <Typography.Link onClick={() => save(record.key)} style={{ marginInlineEnd: 8, }}>
                                Save
                            </Typography.Link>
                            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                                <a>Cancel</a>
                            </Popconfirm>
                        </span>
                    ) : (
                        <Space.Compact size='medium'>
                            <Button type="text" icon={<EditOutlined style={{ fontSize: '12px' }} />} disabled={editingKey !== ''} onClick={() => edit(record)} />
                            <Button type="text" href={'/tickeranalysis/' + record.symbol} icon={<LineChartOutlined style={{ fontSize: '14px' }} />} />
                            <Popconfirm title="Confirm delete?" onConfirm={() => handleDelete(record.key)}>
                                <Button type="text" icon={<CloseOutlined style={{ fontSize: '12px' }} />} />
                            </Popconfirm>
                        </Space.Compact>
                    )
                } else
                    return null
            },
        },
    ]

    const tdgBkCustomColumns = tdgBkDefaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                type: col.type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const components = {
        body: {
            row: (props) => {
                const { className, ...restProps } = props
                const isAnimating = className?.includes('animated-row')    // Use the class name to know which row to animate
                const RowComponent = isAnimating ? animated.tr : 'tr'
                return <RowComponent {...restProps} style={isAnimating ? springsRow : {}} />
            },
            cell: EditableCellRow,
        },
    }

    const paginationOptions = {
        defaultPageSize: 10,
        showSizeChanger: true,
    }


    // HOLDINGS -------------------------------------------------------------------------------------------------------------------------------------

    const HoldingsColumns = [
        {
            title: <span style={{ paddingLeft: '0.2em' }}>Symbol</span>,
            dataIndex: 'symbol',
            width: '9%',
            sorter: {
                compare: (a, b) => a.symbol.localeCompare(b.symbol),
                multiple: 1
            },
            render: (symbol) =>
                <Space align="center">
                    <Image src={getLogoUrl(symbol)} alt={symbol} width={16} preview={false} />
                    <Text>{symbol}</Text>
                </Space>
            ,
        },
        {
            title: <><span className='ai-logo'>AI</span> Status</>,
            dataIndex: 'aiStatus',
            align: 'center',
        },
        {
            title: 'Buy Date',
            dataIndex: 'date',
            align: 'center',
            render: (text, record) => { return dayjs(text).format(DATE_DAY_FIRST) },
            sorter: {
                compare: (a, b) => a.date - b.date,
                multiple: 1
            }
        },
        {
            title: 'Nbr Of Shares',
            width: '9%',
            dataIndex: 'shares',
            align: 'center',
            sorter: {
                compare: (a, b) => a.shares - b.shares,
            }
        },
        {
            title: 'Price',
            dataIndex: 'price',
            align: 'center',
            render: (_, record) => '$ ' + formatNumber(record.price)
        },
        {
            title: 'Fee',
            dataIndex: 'fee',
            align: 'center',
            render: (_, record) => '$ ' + formatNumber(record.fee)
        },
        {
            title: 'Total Invested',
            dataIndex: 'totalInvested',
            align: 'center',
            render: (_, record) => {
                return '$ ' + formatNumber(record.totalInvested)
            },
            sorter: {
                compare: (a, b) => a.totalInvested - b.totalInvested,
            }
        },
        {
            title: 'Dividends Paid',
            dataIndex: 'totalDividendsPaid',
            align: 'center',
            render: (_, record) => {
                if (record.totalDividendsPaid !== undefined)
                    return '$ ' + formatNumber(record.totalDividendsPaid)
                else
                    return '-'
            },
            sorter: {
                compare: (a, b) => a.totalDividendsPaid - b.totalDividendsPaid,
            }
        },
        {
            title: 'Gain/Loss',
            width: '13%',
            dataIndex: 'priceChange',
            align: 'center',
            render: (_, record) => {
                var style = record.changeStyle
                if (record.animate)
                    style = { ...style, ...springs }
                return <animated.span style={style}>{renderGainLoss(record.gainLoss, record.change)}</animated.span>
            },
            sorter: {
                compare: (a, b) => a.change - b.change,
            }
        },
        {
            title: 'Gain/Loss With Dividends',
            width: '13%',
            dataIndex: 'gainLoss',
            align: 'center',
            render: (_, record) => {
                var style = record.changeStyleDivid
                if (record.animate)
                    style = { ...style, ...springs }
                return <animated.span style={style}>{renderGainLoss(record.gainLossDivid, record.changeDivid)}</animated.span>
            },
            sorter: {
                compare: (a, b) => a.gainLoss - b.gainLoss,
            }
        },
    ]

    // EFFECTS --------------------------------------------------------------------------------------------------------------------------------------

    useEffect(() => {

        const fetchData = async () => {
            const stockTdgBk = []
            const holdings = []
            const accessToken = await getAccessTokenSilently()

            // Set trading book and get tickers infos
            var tickersInfosList = null
            if (tradingBook !== undefined) {

                console.debug("Use parameter trading book")

                const resp = await getTickersInfos(accessToken, tradingBookGetUniqueSymbols(tradingBook))
                tickersInfosList = resp.data

                tradingBookComputeGains(tradingBook, tickersInfosList)

            } else if (tradingBook === undefined && userContext != null && userContext.stockTradingBook) {

                console.debug("Use user context trading book")

                tradingBook = userContext.stockTradingBook

                const resp = await getTickersInfos(accessToken, getUserHoldingsSymbol(tradingBook))
                tickersInfosList = resp.data
            }

            // Set tables data
            if (tradingBook !== undefined) {

                // Convert all dates to dayjs UTC object > required for sort comparaison
                tradingBook.forEach(item => {
                    item.date = dayjs.utc(item.date)
                })

                // TODO : Sort trading book descending > Should already be sorted in the DB
                tradingBook.sort((a, b) => b.date - a.date)

                rowKeyCount = 0
                for (const stockTradBkEntry of tradingBook) {
                    stockTdgBk.push({
                        key: rowKeyCount,
                        symbol: stockTradBkEntry.symbol,
                        date: stockTradBkEntry.date,
                        actionType: stockTradBkEntry.actionType,
                        shares: stockTradBkEntry.shares,
                        price: stockTradBkEntry.price,
                        totalDividendsPaid: stockTradBkEntry.totalDividendsPaid,
                        gainLoss: stockTradBkEntry.gainLoss,
                        gainLossAmount: stockTradBkEntry.gainLossAmount,
                        fee: stockTradBkEntry.fee,
                    })

                    if (stockTradBkEntry.remainingShares > 0 && stockTradBkEntry.actionType === 'BUY') {
                        const tkrInfos = tickersInfosList[stockTradBkEntry.symbol]
                        const tkrQuote = tkrInfos.quote

                        const totalInvested = (stockTradBkEntry.price * stockTradBkEntry.remainingShares) + stockTradBkEntry.fee
                        const totalSellCurrentQuote = tkrQuote.close * stockTradBkEntry.remainingShares

                        // Calculate dividends paid
                        let totalDividendsPaid = 0
                        if (tkrInfos.dividends) {
                            totalDividendsPaid = calculatePaidDividends(stockTradBkEntry.remainingShares, stockTradBkEntry.date, new Date(), tkrInfos.dividends)
                        }

                        // Compute gain/loss
                        const gainLoss = computeHoldingsGainLoss(totalInvested, totalSellCurrentQuote, totalDividendsPaid)

                        let holdingAIStatus = <TagAIHoldingStatusHold />
                        if (tkrInfos.oneDay.activeSignalSellL2)
                            holdingAIStatus = <Space><IconSignalSellL2 /> Sell Signal L2</Space>
                        else if (tkrInfos.oneDay.activeSignalSellL1)
                            holdingAIStatus = <Space><IconSignalSellL1 /> Sell Signal L1</Space>
                        else if (tkrInfos.oneDay.activeTakeProfitL1)
                            holdingAIStatus = <Space><IconEMA /> Take Profit L1</Space>
                        else if (tkrInfos.oneDay.activeTakeProfitL2)
                            holdingAIStatus = <Space><IconMA /> Take Profit L2</Space>

                        holdings.push({
                            key: rowKeyCount,
                            symbol: stockTradBkEntry.symbol,
                            aiStatus: holdingAIStatus,
                            date: stockTradBkEntry.date,
                            shares: stockTradBkEntry.remainingShares,
                            price: stockTradBkEntry.price,
                            lastPrice: tkrQuote.close,
                            fee: stockTradBkEntry.fee,
                            totalInvested: totalInvested,
                            totalDividendsPaid: totalDividendsPaid,
                            ...gainLoss
                        })
                    }

                    rowKeyCount++
                }

                setStockTradingBookData(stockTdgBk)
                setHoldingsData(holdings)
                setIsLoading(false)
            }
        }
        fetchData()

        if (intervalId == null) {
            intervalId = setInterval(updatePrices, REAL_TIME_INTERVAL)

            // Clear interval on component unmount
            return () => clearInterval(intervalId)
        }
    }, [userContext])

    const tableStockTradingBook = (
        <Card className='card'>
            <Form form={form} component={false}>
                <Table
                    components={components}
                    rowClassName={(record) => (record.key === animatedRow ? 'animated-row' : '')}
                    pagination={{
                        ...tradingBookPagination,
                        onChange: (page) => setTradingBookPagination(prev => ({ ...prev, current: page })),
                    }}
                    size='small'
                    dataSource={stockTradingBookData}
                    columns={tdgBkCustomColumns}
                    scroll={{
                        x: TABLE_SCROLL_X,
                    }} />
            </Form>
        </Card>
    )

    const tableHoldings = (
        <Card className='card'>
            <Table
                pagination={paginationOptions}
                size='small'
                dataSource={holdingsData}
                columns={HoldingsColumns}
                rowClassName={'table-row-pointer'}
                onRow={(record, rowIndex) => navigateToTickerObject(record.symbol)}
                scroll={{
                    x: TABLE_SCROLL_X,
                }}
                summary={(pageData) => {
                    let sumInvested = 0
                    let sumDividendsPaid = 0
                    let sumGainLoss = 0
                    let sumGainLossDivid = 0

                    pageData.forEach(({ totalInvested, totalDividendsPaid, gainLoss, gainLossDivid }) => {
                        sumInvested += totalInvested
                        sumDividendsPaid += totalDividendsPaid
                        sumGainLoss += gainLoss
                        sumGainLossDivid += gainLossDivid
                    })

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={5} />
                            <Table.Summary.Cell className='stylish-cell-top' align='center'>
                                <Text strong>TOTAL</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className='stylish-cell-top' align='center'>
                                <Text strong>$ {formatNumber(sumInvested)}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className='stylish-cell-top' align='center'>
                                <Text strong>$ {formatNumber(sumDividendsPaid)}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className='stylish-cell-top' align='center'>
                                <Text strong style={{ color: getGainLossColor(sumGainLoss) }}>$ {formatNumber(sumGainLoss)}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className='stylish-cell-top' align='center'>
                                <Text strong style={{ color: getGainLossColor(sumGainLossDivid) }}>$ {formatNumber(sumGainLossDivid)}</Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    )
                }}
            />
        </Card>
    )

    // Component is fast enough, not needed
    // Now needed so the Tabs component doesn't load first with the wrong default key, later rendering won't work
    if (isLoading) {
        return <IconLoading />
    }

    // Clear interval on Trading Book tab, otherwise loose focus issue when editing a row
    const switchTab = (activeKey) => {
        setActiveTabKey(activeKey)

        if (activeKey === "holdings") {
            if (intervalId == null) {
                intervalId = setInterval(updatePrices, REAL_TIME_INTERVAL)

                // Clear interval on component unmount
                return () => clearInterval(intervalId)
            }
        } else {
            clearInterval(intervalId)
            intervalId = null
        }
    }

    return (<>
        <Tabs defaultActiveKey={activeTabKey} style={{ marginTop: "25px", overflowX: 'auto' }} destroyInactiveTabPane="true" items={[
            {
                key: 'holdings',
                label: <Text strong>Holdings</Text>,
                children: tableHoldings,
            },
            {
                key: 'tradingBook',
                label: <Text strong>Trading Book</Text>,
                children: tableStockTradingBook,
            }]}
            onChange={(activeKey) => switchTab(activeKey)}
            tabBarExtraContent={(!viewMode && activeTabKey === 'tradingBook') &&
                <Tooltip placement='right' color='orange' title='Entries are auto saved when symbol, shares, and price are filled' mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
                    <Button onClick={handleAdd} type="primary" size='small' disabled={editingKey !== ''}
                        style={{ top: '0em', right: '0em' }}>
                        Add Order
                    </Button>
                </Tooltip>
            }
        />
        {animateChanges()}
    </>)
}

export default TradingBook;


const getUserHoldingsSymbol = (stockTradingBook) => {
    return stockTradingBook
        .filter(item => item.remainingShares > 0 && item.actionType === 'BUY')
        .map(item => item.symbol)
}

const getUniqueSymbolsFromTdgBook = (stockTradingBook) => {
    const uniqueSymbols = new Set()
    stockTradingBook.forEach((item) => {
        uniqueSymbols.add(item.symbol)
    })

    return Array.from(uniqueSymbols)
}

const renderGainLoss = (gainLossAmt, gainLossPrct) => {
    return <>$ {formatNumber(gainLossAmt)} <i style={{ fontSize: 'smaller' }}>({formatPriceChg(gainLossPrct)})</i></>
}

/**
 * Compute holdings gain/loss
 * @param {*} totalInvested 
 * @param {*} totalSellCurrentQuote 
 * @param {*} totalDividendsPaid 
 * @returns Object properties for holdings table
 */
const computeHoldingsGainLoss = (totalInvested, totalSellCurrentQuote, totalDividendsPaid) => {

    const gainLoss = totalSellCurrentQuote - totalInvested
    const change = pourcentage(totalSellCurrentQuote, totalInvested)

    // Including dividends
    const gainLossDivid = totalSellCurrentQuote + totalDividendsPaid - totalInvested
    const changeDivid = pourcentage(totalSellCurrentQuote + totalDividendsPaid, totalInvested)

    return {
        change: change,
        changeStyle: { color: getGainLossColor(change), fontWeight: STYLE_RT_FONT_WEIGHT },
        gainLoss: gainLoss,
        changeDivid: changeDivid,
        changeStyleDivid: { color: getGainLossColor(changeDivid), fontWeight: STYLE_RT_FONT_WEIGHT },
        gainLossDivid: gainLossDivid
    }
}