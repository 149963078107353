import { ProfileOutlined } from '@ant-design/icons';
import { Layout, Button, Table, Modal, Card } from 'antd';
import { IconLoading, } from '../../components/Icons';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { useAuth0 } from "@auth0/auth0-react";
import UserContext from '../../components/UserContext';
import { getColumnSearchProps } from '../../utils/CustomizeTable';
import { getUserList } from '../../services/user.service';
import { printUTCDate } from '../../utils/utils';
import TradingBook from '../../components/TradingBook';
import PageTitle1 from '../../components/PageTitle1';



// PARAMETERS //


const Users = () => {

    const { userContext } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true)
    const { getAccessTokenSilently } = useAuth0()
    const [tableData, setTableData] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState('')
    const emailSearchRef = useRef(null)

    const showModal = (modalContent) => {
        setModalContent(modalContent)
        setIsModalOpen(true)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const paginationOptions = {
        defaultPageSize: 100,
        showSizeChanger: false,
    }

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps(emailSearchRef, 'email')
        },
        {
            title: 'Portfolio',
            dataIndex: 'portfolio',
            key: 'portfolio',
            render: (_, record) => (
                <Button type="link" onClick={() => showModal(<TradingBook tradingBook={record.stockTradingBook} />)} disabled={record.stockTradingBook.length === 0}>
                    <ProfileOutlined />
                </Button>
            )
        },
        {
            title: 'created',
            dataIndex: 'created',
            key: 'created',
            sorter: (a, b) => renderToString(a.created).localeCompare(renderToString(b.created)),
            render: (_, record) => printUTCDate(record.created)
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastUpdated',
            key: 'lastUpdated',
            sorter: (a, b) => renderToString(a.lastUpdated).localeCompare(renderToString(b.lastUpdated)),
            render: (_, record) => printUTCDate(record.lastUpdated)
        },
        {
            title: 'Last Connection',
            dataIndex: 'lastConnection',
            key: 'lastConnection',
            sorter: (a, b) => renderToString(a.lastConnection).localeCompare(renderToString(b.lastConnection)),
            render: (_, record) => printUTCDate(record.lastConnection),
            defaultSortOrder: 'descend'
        },
        {
            title: 'Account Type',
            dataIndex: 'accountType',
            key: 'accountType',
            sorter: (a, b) => renderToString(a.accountType).localeCompare(renderToString(b.accountType)),
        },
        {
            title: 'Trading Profile',
            dataIndex: 'tradingProfile',
            key: 'tradingProfile',
            sorter: (a, b) => renderToString(a.tradingProfile).localeCompare(renderToString(b.tradingProfile)),
        },
        {
            title: 'Chart Mode',
            dataIndex: 'chartMode',
            key: 'chartMode',
            sorter: (a, b) => renderToString(a.chartMode).localeCompare(renderToString(b.chartMode)),
        },
        {
            title: 'Subscription Id',
            dataIndex: 'subscriptionId',
            key: 'subscriptionId',
            sorter: (a, b) => renderToString(a.subscriptionId).localeCompare(renderToString(b.subscriptionId)),
        },
        {
            title: 'Subscription Start',
            dataIndex: 'subscriptionStart',
            key: 'subscriptionStart',
            sorter: (a, b) => renderToString(a.subscriptionStart).localeCompare(renderToString(b.subscriptionStart)),
            render: (_, record) => printUTCDate(record.subscriptionStart)
        },
        {
            title: 'Subscription End',
            dataIndex: 'subscriptionEnd',
            key: 'subscriptionEnd',
            sorter: (a, b) => renderToString(a.subscriptionEnd).localeCompare(renderToString(b.subscriptionEnd)),
            render: (_, record) => printUTCDate(record.subscriptionEnd)
        },
        {
            title: 'Newsletters',
            dataIndex: 'newsletters',
            key: 'newsletters',
            sorter: (a, b) => renderToString(a.newsletters).localeCompare(renderToString(b.newsletters)),
        },
    ]

    useEffect(() => {

        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            const resp = await getUserList(accessToken)
            const users = resp.data

            const datas = users.map(u => ({
                key: u.id,
                email: u.email,
                created: u.created,
                lastUpdated: u.lastUpdated,
                lastConnection: u.lastConnection,
                subscriptionStart: u.subscriptionStart,
                subscriptionEnd: u.subscriptionEnd,
                subscriptionId: u.subscriptionId,
                accountType: u.accountType,
                tradingProfile: u.tradingProfile,
                chartMode: u.chartMode,
                newsletters: u.emailPreferences?.newsletters ? "yes" : "no",
                stockTradingBook: u.stockTradingBook
            }))

            setTableData(datas)
            setIsLoading(false)
        }
        fetchData()

    }, [userContext])

    // userContext needs to be setted otherwise error
    if (userContext == null || (userContext.watchlist.length > 0 && (tableData == null || isLoading))) {
        return (
            <Layout className="site-layout-content">
                <IconLoading />
            </Layout>)
    }

    return (<>
        <PageTitle1> • Users</PageTitle1>

        <Card className='card'>
            <Table size='small' dataSource={tableData} rowClassName={'table-row-pointer'}
                columns={columns}
                pagination={paginationOptions}
                scroll={{
                    x: true,
                }} />
        </Card>

        <Modal title="Details" open={isModalOpen} footer={null} onCancel={handleCancel} destroyOnClose={true} width={1200}>
            {modalContent}
        </Modal>
    </>)
}

export default Users