import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import UserProvider from './components/UserProvider';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import { ANTD_THEME_TOKEN } from './JTIConst';


// MARK : Disable logging in production
if (process.env.NODE_ENV === "production") {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
}

const root = ReactDOM.createRoot(document.getElementById('root'));

// Initialize GA4
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

// Init Facebook pixel
ReactPixel.init(process.env.REACT_APP_FBK_PIXEL_ID);

root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <Auth0ProviderWithNavigate>
            <ConfigProvider theme={ANTD_THEME_TOKEN}>
                <UserProvider>
                    <App />
                </UserProvider>
            </ConfigProvider>
        </Auth0ProviderWithNavigate>
    </BrowserRouter>
    // </React.StrictMode>
);

const SendAnalytics = () => {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });

    ReactPixel.pageView();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
