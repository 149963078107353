import { renderToString } from "react-dom/server"
import { displayData, formatNumberIn, formatNumberToM, formatPourcent } from "./utils"
import { TagResistanceTT, TagSupportTT } from "../components/Tags"

// MARK : TT configs
export const ttContainerStyle = 'style="line-height: 1.9em;"'
export const ttHeaderStyle = 'style="margin-bottom: 0.7em; font-weight: bold; font-size: 1.2em; border-bottom: 1px solid #fff"'
export const ttSubTitleStyle = 'style="margin: 0 0 0.4em 0; font-weight: bold;"'
export const ttSubContainerStyle = 'style="margin: 1.3em 0 0.9em 0; padding-left: 0.5rem; border-left: 1px solid #8c8c8c"'
export const ttAddSubContainer = (content) => {
    return `<div ${ttSubContainerStyle}>${content}</div>`
}
export const ttCommonFormatter = (params) => {
    let html = '<div ' + ttContainerStyle + '>'

    // To see what is available for tooltip data
    // console.debug(JSON.stringify(params))

    // Add candle data
    let candle = params.find(item => item.seriesName === 'Candle')
    if (candle != null && Object.hasOwn(candle, 'value') && candle.value.length >= 4 && candle.data.silent !== true) {
        html = html + `<div ${ttHeaderStyle}>Date: ${candle.axisValueLabel}</div>`
        html = html + `O<b>${candle.value[1]}</b> • H<b>${candle.value[4]}</b> • L<b>${candle.value[3]}</b> • C<b>${candle.value[2]}</b>`
    } else {
        return null     // Case cursor on no candle area > candle.data.silent !== true
    }

    // Add volume data
    let volume = params.find(item => item.seriesName === 'Volume')
    if (volume) {
        html = html + '<br />Volume: <b>' + formatNumberIn(volume.value) + '</b>';
    }

    // Add take profit
    if (candle.data.groupID === 'signalFirstAboveMA' || candle.data.groupID === 'signalFirstAboveEMA') {
        html = html + ttAddSubContainer(candle.data.name)
    }

    // Add signals tags
    const idxBSL1 = params.find(item => item.seriesName === 'Buy Signal L1');
    if (idxBSL1) {
        html = html + ttAddSubContainer(idxBSL1.name)
    }
    const idxBSL2 = params.find(item => item.seriesName === 'Buy Signal L2');
    if (idxBSL2) {
        html = html + ttAddSubContainer(idxBSL2.name)
    }
    const idxSSL1 = params.find(item => item.seriesName === 'Sell Signal L1');
    if (idxSSL1) {
        html = html + ttAddSubContainer(idxSSL1.name)
    }
    const idxSSL2 = params.find(item => item.seriesName === 'Sell Signal L2');
    if (idxSSL2) {
        html = html + ttAddSubContainer(idxSSL2.name)
    }

    // Add buy and sell orders infos
    const buyOrders = params.filter(item => item.seriesName === 'Buy Order');
    if (buyOrders.length > 0) {
        buyOrders.forEach(order => { html = html + ttAddSubContainer(order.name) })
    }
    const sellOrders = params.filter(item => item.seriesName === 'Sell Order');
    if (sellOrders.length > 0) {
        sellOrders.forEach(order => { html = html + ttAddSubContainer(order.name) })
    }

    return html + '</div>'
}

export const ttEarningsFormatter = (params, v) => {

    // To see what is available for tooltip data
    // console.log(JSON.stringify(params))

    return '<div ' + ttContainerStyle + '><div ' + ttHeaderStyle + '>Earnings: ' + params.data.coord[0] + '</div>' +
        ttAddSubContainer(
            '<div ' + ttSubTitleStyle + '">EARNINGS</div>' +
            'Estimate: <b>' + v.epsEstimate + '</b><br />' +
            'Reported: <b>' + displayData(v.epsReported, '') + '</b><br />' +
            'Surprise: <b>' + displayData(formatPourcent(v.epsSurprise), '') + '</b>'
        ) +
        ttAddSubContainer(
            '<div ' + ttSubTitleStyle + '">REVENUE</div>' +
            'Estimate: <b>' + formatNumberToM(v.revenueEstimate) + '</b><br />' +
            'Reported: <b>' + displayData(formatNumberToM(v.revenueReported), '') + '</b><br />' +
            'Surprise: <b>' + displayData(formatPourcent(v.revenueSurprise), '') + '</b>'
        ) +
        'Period Ending: <b>' + v.fiscalDateEnding + '</b>' +
        '</div>'
}

// Use scatter name attribute for meta data
export const createSignal = (d, isLow, sig, color) => {
    const price = isLow ? d.low.toFixed(2) : d.high.toFixed(2)
    const tagSupp = d.scoreSupport > 0 ? renderToString(<TagSupportTT />) : ''
    const tagResis = d.scoreResistance > 0 ? renderToString(<TagResistanceTT />) : ''
    const first = d.signalFirst ? ' <sup><b>1st</b></sup>' : ''
    const content =
        'Date: <b>' + d.datetime + '</b>' +
        '<br />Price: <b>' + price + '</b>' +
        '<br />Tags: ' + tagResis + tagSupp

    return {
        name: '<div ' + ttSubTitleStyle + '">' + renderToString(sig) + first + '</div>' + content,
        // MARK : Distinguish 1st signals
        itemStyle: {
            color: d.signalFirst ? color : 'rgb(255, 255, 255, 0)',
            borderWidth: 1,
            borderColor: color
        },
        emphasis: {
            itemStyle: {
                color: color,
            }
        },
        value: [d.datetime, price],
        tooltip: {
            show: true,
            trigger: 'item',
            formatter: function (params) {
                return '<div ' + ttContainerStyle + '><div ' + ttHeaderStyle + '>' + renderToString(sig) + first + '</div>' + content
            }
        }
    }
}