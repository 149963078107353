import { Col, Row } from 'antd';
import { IconBearFlag, IconBullFlag, IconDividend, IconEarning, IconEMA, IconMA, IconSignalBuyL1, IconSignalBuyL2, IconSignalSellL1, IconSignalSellL2, IconVolume } from '../Icons';
import { BuySignalDesc, SellSignalDesc } from '../TempLang';


const styleCol = { margin: '10px 0 0 0' }


const CandlestickChartTT = () => {

    return (
        <div style={{ margin: '0  0 20px 5px', }}>
            <div><b>AI interactive candlestick chart</b> offering multiple indicators:</div>
            <Row style={styleCol}>
                <b><u>Buy Signals:</u></b>
            </Row>
            <Row style={styleCol}>
                <Col span={12}><IconSignalBuyL1 />Buy Signal L1</Col>
                <Col span={12}><IconSignalBuyL2 />Buy Signal L2</Col>
            </Row>
            <Row style={styleCol}>
                <Col span={24}>{BuySignalDesc}</Col>
            </Row>
            <Row style={styleCol}>
                <b><u>Sell Signals:</u></b>
            </Row>
            <Row style={styleCol}>
                <Col span={12}><IconSignalSellL1 />Sell Signal L1</Col>
                <Col span={12}><IconSignalSellL2 />Sell Signal L2</Col>
            </Row>
            <Row style={styleCol}>
                <Col span={24}>{SellSignalDesc}</Col>
            </Row>
            <Row style={styleCol}>
                <b><u>Other Indicators:</u></b>
            </Row>
            <Row style={styleCol}>
                <Col span={12}><IconEMA /> Take Profit L1</Col>
                <Col span={12}><IconMA /> Take Profit L2</Col>
            </Row>
            <Row style={styleCol}>
                <Col span={12}><IconEarning />Earnings</Col>
                <Col span={12}><IconDividend />Dividend</Col>
            </Row>
            <Row style={styleCol}>
                <Col span={12}><IconBullFlag />Bull flag</Col>
                <Col span={12}><IconBearFlag />Bear flag</Col>
            </Row>
            <Row style={styleCol}>
                <Col span={12}><IconVolume />Volume</Col>
            </Row>
        </div>
    )
}

export default CandlestickChartTT;