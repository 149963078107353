import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { TagAIAverage, TagAIGood, TagAIHigh, TagAILow } from '../components/Tags';
import { COLOR_GREEN, COLOR_PRIMARY_STRONGER, LOG_HIGHLIGHT_STYLE, LOGO_64_URL } from '../JTIConst';


// MARK : NOTUSED
export const getProp = (obj, prop, defaultValue) => {
    if (obj.hasOwnProperty(prop))
        return obj[prop]
    else
        return defaultValue
}

// Calculate the intersection of multiple arrays of strings
// https://frontendroom.com/intersection-of-multiple-arrays-in-js/#calculate-the-intersection-of-multiple-arrays-of-strings
export function arrayIntersection(option) {
    const key = option.key
    const arrays = Array.from(arguments)?.slice(1)
    return arrays?.reduce((acc, currentValue) => {
        return acc.filter(res =>
            currentValue.find(value => value[key] === res[key])
        )
    })
}

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    })
}

export const navigateToTicker = (e, ticker) => {
    // Check if the Cmd (or Ctrl on Windows) key is pressed or if middle click
    if (e.metaKey || e.ctrlKey || e.type === 'auxclick') {
        window.open("/tickeranalysis/" + ticker, '_blank');
    } else {
        window.location.href = "/tickeranalysis/" + ticker;
    }
}

export const navigateToTickerObject = (ticker) => {
    return {
        onClick: (e) => {
            navigateToTicker(e, ticker)
        },
        onAuxClick: (e) => {
            window.open("/tickeranalysis/" + ticker, '_blank');
        },
    }
}

export const mailto = () => {
    const mailtoUrl = 'mailto:justtradeit.co@gmail.com';

    // Open the default email client
    window.location.href = mailtoUrl;
}

export const isMobile = () => {
    // TODO : More efficient in state management library
    const isMobile = window.innerWidth <= 992;
    return isMobile;
}

export const isTablet = () => {
    // TODO : More efficient in state management library
    const isMobile = window.innerWidth <= 1210;
    return isMobile;
}

// NOTUSED : Make isMobile responsive
export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 992)

    useEffect(() => {
        // Function to update window size
        function handleResize() {
            setIsMobile(window.innerWidth <= 992)
        }

        // Add event listener to window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        // return () => {
        //     window.removeEventListener('resize', handleResize);
        // }
    }, []) // Empty dependency array, so it only runs on mount and unmount

    return isMobile;
}

export const formatNumberToB = (number) => {
    const formattedNumber = (number / 1000000000).toFixed(2);
    return `${formattedNumber}B`;
}

export const formatNumberToK = (number) => {
    const formattedNumber = (number / 1000).toFixed(2);
    return `${formattedNumber}K`;
}

export const formatNumberToM = (number) => {
    const formattedNumber = (number / 1000000).toFixed(2);
    return `${formattedNumber}M`;
}

export const formatNumberIn = (number) => {
    if (number >= 1000000000 || number <= -1000000000)
        return formatNumberToB(number)
    else if (number >= 1000000 || number <= -1000000)
        return formatNumberToM(number)
    else if (number >= 1000 || number <= -1000)
        return formatNumberToK(number)

    return number.toFixed(2);
}

export const formatNumber = (number, minFractionDigits = 2, maxFractionDigits = 2) => {
    return number.toLocaleString('en-US', { minimumFractionDigits: minFractionDigits, maximumFractionDigits: maxFractionDigits }).replace(',', ' ')
}

export const formatPourcent = (float) => {
    return (float * 100).toFixed(2) + '%';
}

export const formatPourcentWithoutSign = (float) => {
    return (float * 100).toFixed(2);
}

export const pourcentage = (value, originValue) => {
    return ((value - originValue) / originValue * 100).toFixed(2);
}

export const formatDateAsQuarter = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2);
    const month = date.getMonth() + 1;

    const currentQuarter = Math.floor(month / 3);

    const previousQuarter = currentQuarter === 0 ? 4 : currentQuarter;
    const previousQuarterYear = currentQuarter === 0 ? (date.getFullYear() - 1).toString().slice(-2) : year;

    return `Q${previousQuarter}'${previousQuarterYear}`;
}

export const formatDateToBetterReading = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);

    return `${day} ${month} '${year}`;
}

export const printUTCDate = (datetime) => {
    if (datetime === null)
        return ''

    const utcTime = new Date(datetime)
    const formattedDate = utcTime.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' })

    return formattedDate
}

export const printDateTime = (datetime) => {
    if (datetime === null)
        return ''

    const readableDate = dayjs(datetime).format('D MMM-YY h:mm:ss A');

    return readableDate
}

function getBusinessDayOffset(startDate, daysToAdd) {
    const resultDate = new Date(startDate);

    let addedDays = 0;
    while (addedDays < daysToAdd) {
        resultDate.setDate(resultDate.getDate() + 1);
        if (resultDate.getDay() !== 0 && resultDate.getDay() !== 6) {
            // Check for additional conditions like holidays if needed
            addedDays++;
        }
    }
    return resultDate;
}

export const addBusinessDays = (dateArray, numberOfDays) => {
    const lastDate = new Date(dateArray[dateArray.length - 1].replace(' ', 'T'));
    for (let i = 1; i <= numberOfDays; i++) {
        // dateArray.push(getBusinessDayOffset(lastDate, i).toISOString().substring(0, 10) + ' 00:00');
        dateArray.push(getBusinessDayOffset(lastDate, i).toISOString().substring(0, 10));
    }
    return dateArray
}

// MARK : NOTUSED
export const isESTStockMarketOpen = () => {
    const nowEst = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })

    const dayOfWeek = new Date(nowEst).getDay()
    const hours = new Date(nowEst).getHours()
    const minutes = new Date(nowEst).getMinutes()

    // Check if it's a weekend (Saturday or Sunday)
    if (dayOfWeek === 0 || dayOfWeek === 6) {
        return false
    }

    // Check if it's before 9:30 AM or after 4:00 PM
    if ((hours < 9 || (hours === 9 && minutes < 30)) || hours >= 16) {
        return false
    }

    // Market is open
    return true
}

export const areDatesOnSameDay = (date1, date2) => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    )
}

// Define model efficiency tag
export const getAITag = (totalWinPercent) => {
    var modelEfficiencyTag = <TagAILow />
    if (totalWinPercent >= 78)
        modelEfficiencyTag = <TagAIHigh />
    else if (totalWinPercent >= 69)
        modelEfficiencyTag = <TagAIGood />
    else if (totalWinPercent >= 60)
        modelEfficiencyTag = <TagAIAverage />

    return modelEfficiencyTag
}

// Get defined colors for gain, loss or neutral
export const getGainLossColor = (value) => {

    let color = 'black'
    if (value > 0) {
        color = COLOR_GREEN
    } else if (value < 0) {
        color = COLOR_PRIMARY_STRONGER
    }

    return color
}

// Get defined icons for gain, loss or neutral
export const getGainLossIcon = (value) => {

    let icon = ''
    if (value > 0) {
        icon = <ArrowUpOutlined />
    } else if (value < 0) {
        icon = <ArrowDownOutlined />
    }

    return icon
}

// Format price change in % with defined colors
export const formatPriceChg = (priceChg) => {

    let formatedPriceChg = priceChg + '%'
    if (priceChg > 0)
        formatedPriceChg = '+' + priceChg + '%'
    else if (priceChg < 0)
        formatedPriceChg = priceChg + '%'

    return formatedPriceChg
}

/**
 * Compute trading book.
 * @param {*} tradingBook : A user trading book.
 * @param {*} mapTkrInfos : Trading book ticker infos to calculate dividends.
 */
export const tradingBookComputeGains = async (tradingBook, mapTkrInfos = null) => {

    // Sort trading book ascending
    tradingBook.sort((a, b) => a.date - b.date)

    // Set gainLoss to 0 and reset remaining shares for all buy orders
    tradingBook.filter(order => order.actionType === 'BUY').forEach(buyOrder => {
        buyOrder.gainLoss = null
        buyOrder.remainingShares = buyOrder.shares
    })

    // Use a Set to store unique symbols
    const uniqueSymbols = new Set()
    tradingBook.forEach((item) => {
        uniqueSymbols.add(item.symbol)
    })

    uniqueSymbols.forEach((symbol) => {

        console.debug('%cCOMPUTE SYMBOL: ' + symbol, LOG_HIGHLIGHT_STYLE)

        const allOrdersOfSymbol = tradingBook.filter(item => item.symbol === symbol)

        // Initialize a map to track remaining shares from each buy transaction
        const holdingSharesMap = new Map();
        const buyOrders = allOrdersOfSymbol.filter(order => order.actionType === 'BUY');
        let count = 0
        buyOrders.forEach(buyTransaction => {
            holdingSharesMap.set(count + buyTransaction.date, buyTransaction.shares);
            count++
        })

        console.debug('Initial holdingSharesMap:', holdingSharesMap)

        // Calculate percentage gain or loss for each SELL transaction
        const sellTransactions = allOrdersOfSymbol.filter(transaction => transaction.actionType === 'SELL');
        sellTransactions.forEach(sellTransaction => {

            console.debug(`Compute SELL transaction: [date: ${sellTransaction.date}, shares: ${sellTransaction.shares}]`)

            // Find all relevant BUY transactions before the SELL transaction
            const relevantBuyTransactions = allOrdersOfSymbol
                .filter(transaction => transaction.actionType === 'BUY' && transaction.date <= sellTransaction.date);

            // Calculate gain or loss based on all relevant BUY transactions
            let remainingSharesToSell = sellTransaction.shares
            let totalBuy = 0
            let totalDividendsPaid = 0
            count = 0
            relevantBuyTransactions.forEach(buyOrder => {

                console.debug('Buy transaction date:', buyOrder.date)
                const holdingRemainingShares = holdingSharesMap.get(count + buyOrder.date)
                console.debug('Holding remaining shares:', holdingRemainingShares)

                if (holdingRemainingShares && remainingSharesToSell > 0) {
                    const sellShares = Math.min(holdingRemainingShares, remainingSharesToSell)
                    const buyingPrice = buyOrder.price

                    let total = sellShares * buyingPrice

                    // If all shares of the buy order are still in the holding shares map, meaning no shares sold
                    // > Add the buy order fee on the first shares sold calculation
                    if (buyOrder.shares === holdingRemainingShares)
                        total += buyOrder.fee

                    totalBuy += total

                    const tkrInfos = mapTkrInfos[symbol]
                    if (tkrInfos.dividends) {
                        totalDividendsPaid += calculatePaidDividends(sellShares, dayjs.utc(buyOrder.date), dayjs.utc(sellTransaction.date), tkrInfos.dividends)
                    }

                    console.debug('Nb of shares sold:', sellShares)
                    console.debug('Buying price:', buyingPrice)
                    console.debug('Total:', total)

                    // Update shares holding map
                    const leftShares = holdingRemainingShares - sellShares
                    holdingSharesMap.set(count + buyOrder.date, leftShares)

                    buyOrder.remainingShares = leftShares
                    remainingSharesToSell -= sellShares

                    console.debug('Updated holdingSharesMap:', holdingSharesMap)
                }

                count++
            })

            console.debug('Paid Dividends:', totalDividendsPaid)
            console.debug('Gd Total:', totalBuy)
            console.debug('Remaining shares to sell:', remainingSharesToSell)

            // Add paid dividends to the trading book
            sellTransaction.totalDividendsPaid = totalDividendsPaid

            // Add gain/loss to the trading book
            const totalBuyPlusSellFee = sellTransaction.fee + totalBuy
            const gainLossAmt = (sellTransaction.shares * sellTransaction.price) + totalDividendsPaid - totalBuyPlusSellFee
            const gainLossPrct = gainLossAmt / totalBuyPlusSellFee * 100
            if (remainingSharesToSell > 0)
                // Should not occured case where number shares sold higher than shares bought before
                sellTransaction.gainLoss = Infinity
            else {
                sellTransaction.gainLoss = gainLossPrct
                sellTransaction.gainLossAmount = gainLossAmt
            }

            console.debug('Gain/Loss:', sellTransaction.gainLoss)
            console.debug('Gain/Loss amount:', sellTransaction.gainLossAmount)
            totalBuy = 0
        })

        console.debug('Remaining shares:', holdingSharesMap)
    })

    console.debug('FINAL TRADING BOOK:', tradingBook)

    // Sort trading book back desending
    tradingBook.sort((a, b) => b.date - a.date)
}

//
export const tradingBookGetUniqueSymbols = (tradingBook) => {

    // Use a Set to store unique symbols
    const uniqueSymbols = new Set()
    tradingBook.forEach((item) => {
        uniqueSymbols.add(item.symbol)
    })

    return Array.from(uniqueSymbols)
}

//
export const calculatePaidDividends = (shares, purchaseDate, sellDate, dividendHistory) => {
    const purchaseDateTime = new Date(purchaseDate);

    let totalDividends = 0;
    for (const [dateStr, dividend] of Object.entries(dividendHistory)) {
        const dividendDate = new Date(dateStr);
        if (purchaseDateTime < dividendDate && dividendDate <= sellDate) {
            totalDividends += dividend;
        }
    }

    return totalDividends * shares;
}

//
export const displayData = (data, defaultValue = '-') => {
    return data === 'NaN%' || data === 'NaN' || data === 'na' || !data ? defaultValue : data
}

//
export const getLogoUrl = (symbol) => {

    // Manage crypto logos
    const regexCryptoPattern = /^[A-Za-z0-9]{3,4}-[A-Za-z0-9]{3,4}$/;
    if (regexCryptoPattern.test(symbol))
        symbol = symbol.split('-')[0]

    let logoUrl = LOGO_64_URL + '/' + symbol + '.webp'

    return logoUrl
}