import './App.css';
import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";
import { AuthenticationGuard } from "./components/authentication-guard";
import { scrollToTop } from './utils/utils';
import AppLayout from "./pages/AppLayout";
import Home from './pages/Home';
import Landing from './pages/landings/Landing';
import Demo from './pages/Demo';
import Pricing from "./pages/Pricing";
import Watchlist from "./pages/Watchlist";
import Screener from './pages/Screener';
import TickerAnalysis from "./pages/TickerAnalysis";
import MacroView from "./pages/MacroView";
import NewUserStartup from "./pages/NewUserStartup";
import AboutJTI from "./pages/AboutJTI";
import Terms from "./pages/Terms";
import Error from "./pages/Error";
import AIAnalysisDocu from './pages/AIAnalysisDocu';
import Privacy from './pages/Privacy';
import Login from './pages/Login';
import Account from './pages/Account';
import Portfolio from './pages/Portfolio';
import Blog from "./pages/Blog";
import SwingResilientStrategy from './pages/blog/SwingResilientStrategy';
import TradingChecklist from './pages/blog/TradingChecklist';
import Admin from './pages/admin/Admin';


function App() {

    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {

        // Save utm id in user session if present
        const utmId = searchParams.get('utm_id')
        if (utmId !== null) {
            sessionStorage.setItem('utm_id', utmId);
        }

        // Scroll to the top when the location (route) change
        scrollToTop()
    }, [location])

 
    // MARK: Protect pages
    return (
        <Routes>
            <Route path="/" element={<AppLayout />}>
                <Route index element={<Home />} />
                <Route path="home" element={<Home />} />
                <Route path="landing" element={<Landing />} />

                <Route path="login" element={<Login />} />
                <Route path="demo" element={<Demo />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="newuserstartup" element={<AuthenticationGuard component={NewUserStartup} />} />

                <Route path="screener" element={<AuthenticationGuard component={Screener} />} />
                <Route path="watchlist" element={<AuthenticationGuard component={Watchlist} />} />
                <Route path="portfolio" element={<AuthenticationGuard component={Portfolio} />} />
                <Route path="tickeranalysis" element={<AuthenticationGuard component={TickerAnalysis} />} />
                <Route path="tickeranalysis/:paramTicker" element={<AuthenticationGuard component={TickerAnalysis} />} />
                <Route path="macroview" element={<AuthenticationGuard component={MacroView} />} />

                <Route path="blog" element={<Blog />} />
                <Route path="/blog/swingresilientstrategy" element={<SwingResilientStrategy />} />
                <Route path="/blog/tradingchecklist" element={<TradingChecklist />} />
                
                <Route path="account" element={<AuthenticationGuard component={Account} />} />
                {/* TODO : Implement admin guard */}
                <Route path="admin" element={<AuthenticationGuard component={Admin} />} />
                <Route path="admin/:paramMenuKey" element={<AuthenticationGuard component={Admin} />} />

                <Route path="aboutjti" element={<AboutJTI />} />
                <Route path="aianalysisdocu" element={<AIAnalysisDocu />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms" element={<Terms />} />

                <Route path="*" element={<Error />} />
            </Route>
        </Routes>
    )
}

export default App;