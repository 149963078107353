import { useAuth0 } from "@auth0/auth0-react";
import { getInstanceByDom, init } from 'echarts';
import React, { useEffect, useRef, useState } from "react";
import { renderToString } from 'react-dom/server';
import { CHART_CANDLE_STYLE_LIGHT, CHART_COLOR_BLUE, CHART_COLOR_RED, CHART_INDEXES_CANDLE_HISTORY, CHART_TT_STYLE, CHART_VOL_STYLE_LIGHT, COLOR_SIGNAL_BUY_L1, COLOR_SIGNAL_BUY_L2, COLOR_SIGNAL_SELL_L1, COLOR_SIGNAL_SELL_L2 } from '../../JTIConst';
import { getTickerHistory } from "../../services/ticker.service";
import '../../styles/IndexesView.css';
import { ttCommonFormatter } from '../../utils/chartUtils';
import { addBusinessDays, isMobile } from '../../utils/utils';
import { TagResistanceTT, TagSupportTT } from '../Tags';


// Chart configurations
const CHART_POSITION_LEFT = 0
const CHART_POSITION_RIGHT = 50
const CHART_HEIGHT = 312
const VOL_HEIGHT = 30


const IndexCandle = (props) => {

    // PARAMETERS
    const CHART_DAYS_OFFSET = 5
    const CHART_ANIM_SIGNAL_DURATION = 1000

    const { getAccessTokenSilently } = useAuth0();
    const chartRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState({});

    useEffect(() => {
        const fetchData = async () => {

            setLoading(true);

            // Request data
            const accessToken = await getAccessTokenSilently()
            const dataHistory = await getTickerHistory(accessToken, props.ticker, props.interval, CHART_INDEXES_CANDLE_HISTORY)
            var dataFiltered = dataHistory.data.filter(d => d.open !== 'NaN')

            // Format dates
            dataFiltered = dataFiltered.map((obj) => ({
                ...obj,
                datetime: obj.datetime.slice(0, -6),
            }))


            // Chart x-axis
            const timestamp = dataFiltered.map(d => d.datetime)

            // Add right margin to the candlestick chart
            addBusinessDays(timestamp, CHART_DAYS_OFFSET)
            const seriesCandle = dataFiltered.map((d) => ([d.open.toFixed(2), d.close.toFixed(2), d.low.toFixed(2), d.high.toFixed(2)]))
            for (let i = 1; i <= CHART_DAYS_OFFSET; i++)
                seriesCandle.push([])

            const seriesMA50 = dataFiltered.map((d) => (d.ma50))
            const seriesEMA30 = dataFiltered.map((d) => (d.ema30))

            // SIGNALS

            const mapFunc = (d, isLow) => {
                const tagSupp = d.tagSignalSupport == true ? renderToString(<TagSupportTT />) : ''
                const tagResis = d.tagSignalResistance == true ? renderToString(<TagResistanceTT />) : ''

                return {
                    name: 'Signal tags : ' + tagResis + tagSupp,
                    value: [d.datetime, isLow ? d.low.toFixed(2) : d.high.toFixed(2)],
                }
            }
            const scatterBuyL1 = dataFiltered.filter(d => d.signalBullBuyL1 !== 'NaN').map(d => mapFunc(d, true)).concat(dataFiltered.filter(d => d.signalBearBuyL1 !== 'NaN').map(d => mapFunc(d, true)))
            const scatterBuyL2 = dataFiltered.filter(d => d.signalBullBuyL2 !== 'NaN').map(d => mapFunc(d, true)).concat(dataFiltered.filter(d => d.signalBearBuyL2 !== 'NaN').map(d => mapFunc(d, true)))
            const scatterSellL1 = dataFiltered.filter(d => d.signalBullSellL1 !== 'NaN').map(d => mapFunc(d, false)).concat(dataFiltered.filter(d => d.signalBearSellL1 !== 'NaN').map(d => mapFunc(d, false)))
            const scatterSellL2 = dataFiltered.filter(d => d.signalBullSellL2 !== 'NaN').map(d => mapFunc(d, false)).concat(dataFiltered.filter(d => d.signalBearSellL2 !== 'NaN').map(d => mapFunc(d, false)))



            const seriesVol = dataFiltered.map((d) => (d.volume))
            for (let i = 1; i <= CHART_DAYS_OFFSET; i++)
                seriesVol.push(0)


            const seriesShift = dataFiltered.filter(d => d.shift === true).map(d => (
                [{
                    name: ((d.trendFlag === 'Bull') ? 'Bull Flag' : 'Bear Flag'),
                    y: '10%',
                    xAxis: d.datetime,
                    symbol: 'none',
                    label: {
                        show: false
                    },
                    lineStyle: {
                        color: ((d.trendFlag === 'Bull') ? 'green' : 'red'),
                        width: 0.8
                    },
                    emphasis: {
                        label: {
                            show: true,
                            position: 'start',
                            padding: -15,
                            color: ((d.trendFlag === 'Bull') ? 'green' : 'red'),
                        }
                    }
                },
                {
                    yAxis: d.high,
                    xAxis: d.datetime,
                    symbol: 'circle',
                    symbolSize: 0,
                    label: {
                        show: false
                    },
                    emphasis: {
                        label: {
                            show: true,
                            color: ((d.trendFlag === 'Bull') ? 'green' : 'red'),
                        }
                    },
                }]))

            // CHART OPTIONS ------------------------------------------------------------------------------------------------------------------------

            setOptions({
                legend: {
                    top: 'auto',
                    [isMobile() ? 'left' : 'right']: 'auto',    // MOBILE
                    data: [],
                    itemGap: 15,
                    itemWidth: 11,
                },
                animation: false,
                tooltip: {
                    ...CHART_TT_STYLE,
                    // Not working
                    // className: 'echarts-tooltipa',
                    triggerOn: 'mousemove',
                    trigger: 'axis',
                    formatter: ttCommonFormatter
                },
                axisPointer: {
                    link: [
                        {
                            xAxisIndex: [0, 1]
                        }
                    ]
                },
                grid: [
                    {
                        top: 5,
                        left: CHART_POSITION_LEFT,
                        right: CHART_POSITION_RIGHT,
                        height: CHART_HEIGHT
                    },
                    {
                        top: CHART_HEIGHT - VOL_HEIGHT,
                        left: CHART_POSITION_LEFT,
                        right: CHART_POSITION_RIGHT,
                        height: VOL_HEIGHT
                    }
                ],
                xAxis: [
                    {
                        type: 'category',
                        data: timestamp,
                        boundaryGap: false,
                        axisLine: { lineStyle: { color: '#777' } },
                        min: 'dataMin',
                        max: 'dataMax',
                        axisPointer: {
                            show: true
                        },
                    },
                    {
                        type: 'category',
                        gridIndex: 1,
                        data: timestamp,
                        boundaryGap: false,
                        splitLine: { show: false },
                        axisLabel: { show: false },
                        axisTick: { show: false },
                        axisLine: { lineStyle: { color: '#777' } },
                        min: 'dataMin',
                        max: 'dataMax',
                    }
                ],
                yAxis: [
                    {
                        scale: true,
                        position: 'right',
                        axisLine: { lineStyle: { color: '#777' } },
                        splitNumber: 5,
                        splitLine: {
                            show: true,
                            lineStyle: { opacity: 0.4 }
                        },
                        axisTick: { show: false },
                        axisPointer: {
                            show: true,
                        },
                        axisLabel: {
                            showMinLabel: false,
                        }
                    },
                    {
                        show: false,
                        gridIndex: 1,
                        axisPointer: {
                            show: false,
                        }
                    }
                ],

                // CHART SERIES ---------------------------------------------------------------------------------------------------------------------

                series: [
                    {
                        type: 'candlestick',
                        name: 'Candle',
                        data: seriesCandle,
                        itemStyle: CHART_CANDLE_STYLE_LIGHT,
                        markLine: {
                            symbol: ['none', 'none'],
                            data: seriesShift,
                        },
                    },
                    {
                        name: 'MA50',
                        type: 'line',
                        data: seriesMA50,
                        smooth: true,
                        symbol: "line",     // For symbol use in the legend
                        showSymbol: false,
                        color: CHART_COLOR_BLUE,
                        lineStyle: {
                            width: 1
                        }
                    },
                    {
                        name: 'EMA30',
                        type: 'line',
                        data: seriesEMA30,
                        smooth: true,
                        symbol: "line",     // For symbol use in the legend
                        showSymbol: false,
                        color: CHART_COLOR_RED,
                        lineStyle: {
                            width: 1
                        }
                    },
                    {
                        name: 'Buy Signal L1',
                        animation: true,
                        symbolSize: 7,
                        color: COLOR_SIGNAL_BUY_L1,
                        data: scatterBuyL1,
                        type: 'scatter',
                        animationDuration: CHART_ANIM_SIGNAL_DURATION,
                        animationDurationUpdate: CHART_ANIM_SIGNAL_DURATION,
                        animationDelay: 0,
                    },
                    {
                        name: 'Buy Signal L2',
                        animation: true,
                        symbolSize: 7,
                        color: COLOR_SIGNAL_BUY_L2,
                        data: scatterBuyL2,
                        type: 'scatter',
                        animationDuration: CHART_ANIM_SIGNAL_DURATION,
                        animationDurationUpdate: CHART_ANIM_SIGNAL_DURATION,
                        animationDelay: 0,
                    },
                    {
                        name: 'Sell Signal L1',
                        animation: true,
                        symbolSize: 7,
                        color: COLOR_SIGNAL_SELL_L1,
                        data: scatterSellL1,
                        type: 'scatter',
                        animationDuration: CHART_ANIM_SIGNAL_DURATION,
                        animationDurationUpdate: CHART_ANIM_SIGNAL_DURATION,
                        animationDelay: CHART_ANIM_SIGNAL_DURATION,
                    },
                    {
                        name: 'Sell Signal L2',
                        animation: true,
                        symbolSize: 7,
                        color: COLOR_SIGNAL_SELL_L2,
                        data: scatterSellL2,
                        type: 'scatter',
                        animationDuration: CHART_ANIM_SIGNAL_DURATION,
                        animationDurationUpdate: CHART_ANIM_SIGNAL_DURATION,
                        animationDelay: CHART_ANIM_SIGNAL_DURATION,
                    },
                    {
                        name: 'Volume',
                        type: 'bar',
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: CHART_VOL_STYLE_LIGHT,
                        emphasis: {
                            itemStyle: {
                                color: '#140'
                            }
                        },
                        data: seriesVol
                    },
                ]
            })
            setLoading(false);
        }
        fetchData();
    }, [props.ticker, props.interval]);     // With only props the component re-render after pin item to the watchlist, I don't know why but it doesn't by specifying the properties

    useEffect(() => {
        var chart = null;
        if (chartRef.current !== null) {        // Set by first return with no useEffect
            chart = init(chartRef.current);
            loading === true ? chart.showLoading() : chart.hideLoading();
        }

        // Add chart resize listener
        // ResizeObserver is leading to a bit janky UX
        function resizeChart() {
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart);

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
        };
    }, [loading]);                              // Run first to init echarts instance and dispose

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            chart.setOption(options);
        }
    }, [options]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function


    return (
        <div ref={chartRef} className='indexCandle' />
    )
}

export default IndexCandle;
