import { useAuth0 } from "@auth0/auth0-react";
import dayjs from 'dayjs';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getTickersInfos } from "../services/ticker.service";
import { deleteTickerFromWatchlist, getUser, updateStockTradingBook, updateUser, updateWatchlist } from "../services/user.service";
import { tradingBookComputeGains, tradingBookGetUniqueSymbols } from '../utils/utils';
import UserContext from './UserContext';


const UserProvider = ({ children }) => {
    const [tourOpenChartAnalysis, setTourOpenChartAnalysis] = useState(false);
    const [userContext, setUserContext] = useState(null);
    const { user, isLoading, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    // Update the user context + update the user in the database
    const updateUserContext = async (userData) => {
        setUserContext(userData)

        const accessToken = await getAccessTokenSilently();
        const { error } = await updateUser(accessToken, userData);
        
        if(error !== null)
            return false
        
        return true
    }

    // Update user watchlist
    const updateUserWatchlist = async (newList) => {
        userContext.watchlist = newList
        setUserContext(userContext);

        const accessToken = await getAccessTokenSilently()
        updateWatchlist(accessToken, userContext.id, newList)
    }

    const updateUserStockTradingBook = async (newStockTradingBook) => {
        userContext.stockTradingBook = newStockTradingBook

        // Trigger rerendering for childs components
        setUserContext(state => ({
            ...state,
            stockTradingBook: newStockTradingBook
        }))

        const accessToken = await getAccessTokenSilently()
        updateStockTradingBook(accessToken, userContext.id, newStockTradingBook)
    }

    const doIHold = (symbol) => {
        return userContext.stockTradingBook.some(item => item.symbol === symbol && item.remainingShares > 0)
    }

    // Swicht alert for a specific ticker
    const switchAlert = async (ticker) => {

        const idx = userContext.watchlist.findIndex(tkrUserParam => tkrUserParam.ticker === ticker)

        if (idx !== -1) {   // Ticker found
            userContext.watchlist[idx].alert = !userContext.watchlist[idx].alert;
            updateUserWatchlist(userContext.watchlist)
        } else {
            console.log(`Ticker '${ticker}' not found in user watchlist`);
        }
    }

    const delTickerFromWatchlist = async (delTicker) => {

        userContext.watchlist = userContext.watchlist.filter(tkrUserParam => tkrUserParam.ticker !== delTicker)
        setUserContext(userContext);
        // setUserContext({...userContext});    // Propagate event to all useEffect > reload entire table less efficient than removing only the item index

        const accessToken = await getAccessTokenSilently();
        deleteTickerFromWatchlist(accessToken, userContext.id, delTicker)
    }

    useEffect(() => {
        const fetchData = async () => {
            if (!isLoading && user && userContext == null) {

                const accessToken = await getAccessTokenSilently()
                const userResult = await getUser(accessToken)

                // console.log("Auth0 user context: ", JSON.stringify(user))
                // console.log("DB user context: ", JSON.stringify(userResult))

                // If user is not registered in the DB, redirect to newuserstartup page to create the user
                if (userResult.data == null)
                    navigate("/newuserstartup")
                else {
                    // Compute the stock trading book
                    const tdgBook = userResult.data.stockTradingBook
                    const resp = await getTickersInfos(accessToken, tradingBookGetUniqueSymbols(tdgBook))

                    // Convert all dates to dayjs UTC object > required for sort comparaison
                    tdgBook.forEach(item => {
                        item.date = dayjs.utc(item.date)
                    })

                    tradingBookComputeGains(tdgBook, resp.data)
                    setUserContext(userResult.data)
                }
            }
        };
        fetchData();
    }, [isLoading, user, getAccessTokenSilently]);


    return (
        <UserContext.Provider value={{ 
            userContext, 
            setUserContext, 
            updateUserContext, 
            updateUserWatchlist, 
            delTickerFromWatchlist, 
            switchAlert, 
            updateUserStockTradingBook, 
            doIHold,
            tourOpenChartAnalysis,
            setTourOpenChartAnalysis
        }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;
