import { AimOutlined, MailOutlined } from '@ant-design/icons';
import { animated, useChain, useSpring, useSpringRef, useTrail } from '@react-spring/web';
import { Col, Radio, Row, Space, Typography } from 'antd';
import { useInView } from 'react-intersection-observer';
import { IconSignalBuyL2, IconSignalSellL2 } from '../../../components/Icons';
import { TagAITagging } from '../../../components/Tags';
import { COLOR_PRIMARY, tradingProfileOptions } from '../../../JTIConst';
import styles from '../../../styles/landings/pages/Enrollement1.module.css';
import { isMobile } from '../../../utils/utils';


// SHARED VARIABLES //
const { Title, Text } = Typography
const IS_MOBILE = isMobile()


const Enrollement1 = () => {

    // SPRING EFFECTS //

    const [refEnrollement, inViewEnrollement] = useInView({
        triggerOnce: true,
        threshold: IS_MOBILE ? 0.15 : 0.4,
    })

    const springRefEnrollementTitle = useSpringRef()
    const springEnrollementTitle = useSpring({
        ref: springRefEnrollementTitle,
        config: { duration: 600 },
        from: { opacity: inViewEnrollement ? 0 : 0, },
        to: {
            opacity: inViewEnrollement ? 1 : 0,
        },
    })

    const springRefEnrollement = useSpringRef()
    const trailEnrollement = useTrail(tradingProfileOptions.length, {
        ref: springRefEnrollement,
        config: { duration: 400 },
        from: {
            opacity: inViewEnrollement ? 0 : 1,
        },
        to: {
            opacity: inViewEnrollement ? 1 : 0,
        },
    });
    useChain([springRefEnrollementTitle, springRefEnrollement])


    return (
        <div className="pageEnrollement" ref={refEnrollement}>
            <div className="landing-content">
                <Row justify="space-around">
                    <animated.div className='enrollement-title' style={springEnrollementTitle}>Effortless Trading Strategy <span style={{ color: COLOR_PRIMARY }}>In Few Steps</span></animated.div>
                </Row>
                <Row gutter={{ xs: 0, sm: 80 }} className={styles['items-container']}>
                    <Col xs={24} md={8}>
                        <animated.div style={trailEnrollement[0]}>
                            <Title level={2} className='page-title'>
                                <div className='title-number'>1.</div>Select your Trading Profile
                            </Title>
                            <Text className='page-description'>
                                Choose the trading profile that matches your lifestyle:
                                <div style={{ padding: '1em 0 0 4.5em' }}>
                                    <Radio.Group optionType="button" buttonStyle="solid" value={2} >
                                        <Space direction="vertical" size={IS_MOBILE ? 'middle' : 'middle'}>
                                            <Radio value={1} className='radioButton'>{tradingProfileOptions[0].label}</Radio>
                                            <Radio value={2} className='radioButton'>{tradingProfileOptions[1].label}</Radio>
                                            <Radio value={3} className='radioButton'>{tradingProfileOptions[2].label}</Radio>
                                        </Space>
                                    </Radio.Group>
                                </div>
                            </Text>

                        </animated.div>
                    </Col>
                    <Col xs={24} md={8}>
                        <animated.div style={trailEnrollement[1]}>
                            <Title level={2} className='page-title'>
                                <div className='title-number'>2.</div>Find Opportunities to Invest In
                            </Title>
                            <Text className='page-description'>
                                Use the <b>Screener</b> to find the best market opportunities based on our <b>AI Signals</b> &nbsp;<IconSignalBuyL2 /> <IconSignalSellL2 /> 
                                and <TagAITagging style={{ marginRight: '0' }} />, then add them to your <b>Watchlist</b>.
                                {!IS_MOBILE &&
                                    <div align='center'>
                                        <AimOutlined style={{ fontSize: '3em', marginTop: '0.4em' }} />
                                    </div>
                                }
                            </Text>
                        </animated.div>
                    </Col>
                    <Col xs={24} md={8}>
                        <animated.div style={trailEnrollement[2]}>
                            <Title level={2} className='page-title'>
                                <div className='title-number'>3.</div>Let the AI Model Work for You
                            </Title>
                            <Text className='page-description'>
                                The <b>AI Model</b> analyzes all items in your <b>watchlist daily</b>,
                                sending you notifications on <b>the best Buy and Sell Momentum</b> aligned with your <b>Trading Profile</b>.
                                {!IS_MOBILE &&
                                    <div align='center'>
                                        <MailOutlined style={{ fontSize: '3em', marginTop: '0.4em' }} />
                                    </div>
                                }
                            </Text>
                        </animated.div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Enrollement1;