import { Card } from 'antd';
import { getInstanceByDom, init } from 'echarts';
import React, { useEffect, useRef, useState } from "react";
import { CHART_COLOR_STYLES, CHART_LABEL_STYLE } from '../../JTIConst';
import '../../styles/IndexesView.css';
import { formatNumberIn } from '../../utils/utils';


// PARAMETERS //


// SHARED VARIABLES //


const PieChart = ({ height, cardStyle, color, filled = true, legend = true, extra, ...props }) => {

    const chartRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState({});


    useEffect(() => {

        setLoading(true);

        // Set colors
        let i = color ? color : 0 // Starting index for the color
        const colorCount = CHART_COLOR_STYLES.length // Total number of colors

        const dataWithColor = props.data.map((item, index) => ({
            ...item,
            itemStyle: {
                color: filled
                    ? CHART_COLOR_STYLES[(i + index) % colorCount]
                    : 'transparent',
            },
        }))

        setOptions({
            tooltip: {
                trigger: 'item',
                borderWidth: 0,
                valueFormatter: (value) => {
                    if (isNaN(value))
                        return '-'

                    return props.currency ? '$ ' + value.toFixed(2) : formatNumberIn(value)
                }
            },
            legend: {
                show: legend,
            },
            series: [
                {
                    name: 'Industry',
                    type: 'pie',
                    radius: ['20%', '75%'],
                    itemStyle: {
                        borderRadius: 4,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: true,
                        ...CHART_LABEL_STYLE
                    },
                    emphasis: {
                        label: {
                            fontSize: 15,
                            fontWeight: 'bold'
                        }
                    },
                    data: dataWithColor
                }
            ]
        })

        setLoading(false);
    }, [props.data])


    useEffect(() => {
        var chart = null;
        if (chartRef.current !== null) {        // Set by first return with no useEffect
            chart = init(chartRef.current);
            loading === true ? chart.showLoading() : chart.hideLoading()
        }

        // Add chart resize listener
        // ResizeObserver is leading to a bit janky UX
        function resizeChart() {
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart)

        // Return cleanup function
        return () => {
            chart?.dispose()
            window.removeEventListener("resize", resizeChart)
        }
    }, [loading]);                              // Run first to init echarts instance and dispose

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current)
            chart.setOption(options)
        }
    }, [options]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function


    return (
        <Card title={props.title} className='card' style={cardStyle} extra={extra}>
            <div ref={chartRef} style={{ height: height }} />
        </Card>
    )
}

export default PieChart;
