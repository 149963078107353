import { CHART_COLOR_STYLES, CHART_LABEL_STYLE } from '../../JTIConst';
import { getInstanceByDom, init } from 'echarts';
import React, { useEffect, useRef, useState } from "react";
import '../../styles/IndexesView.css';
import { formatDateAsQuarter, formatNumberIn, isMobile } from '../../utils/utils';
import { Card } from 'antd';


// SHARED VARIABLES //

const IS_MOBILE = isMobile()


// PARAMETERS //

// Chart configuration
const CHART_ZOOM_THROTTLE = 50


const BarChart = ({ height, cardStyle, color, filled, legend, ttFormat, labelOptions, extra, ...props }) => {

    const chartRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState({});

    const showLegend = legend === true
    const itemFilled = filled === true
    const ttFmt = ttFormat === true

    useEffect(() => {

        setLoading(true);

        let legend = []
        let legendSelected = {}
        let series = []
        let i = color ? color : 0
        props.series.map(serie => {
            legend.push({
                name: serie.name,
                itemStyle: {
                    opacity: 0.8
                }
            })
            legendSelected[serie.name] = serie.legendSelected ?? true

            series.push({
                name: serie.name,
                type: 'bar',
                label: labelOptions,
                data: serie.data,
                itemStyle: {
                    color: itemFilled ? CHART_COLOR_STYLES[i] : 'transparent',
                    borderColor: CHART_COLOR_STYLES[i],
                    borderWidth: itemFilled ? 0 : 1.5,
                },
                emphasis: {
                    focus: 'series'
                },
            })
            i++
        })

        setOptions({
            grid: {
                left: '0%',
                right: '0%',
                bottom: '0%',
                top: showLegend ? '10%' : '2%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    shadowStyle: {
                        color: 'rgb(190, 190, 190, 0.1)'
                    }
                },
                valueFormatter: (value) => {
                    if (isNaN(value))
                        return '-'

                    if (ttFmt)
                        return props.currency ? '$ ' + formatNumberIn(value) : formatNumberIn(value)
                    else
                        return props.currency ? '$ ' + value.toFixed(2) : value.toFixed(2)
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    throttle: CHART_ZOOM_THROTTLE,
                    startValue: props.xAxis[props.xAxis.length - 18],
                    // endValue: '100',
                }
            ],
            legend: {
                show: showLegend ? true : false,
                data: legend,
                selected: legendSelected,
                textStyle: {
                    ...CHART_LABEL_STYLE
                }
            },
            yAxis: {
                axisLine: {
                    show: false,
                    lineStyle: {
                        // color: '#333',
                        width: 1
                    },
                },
                axisTick: {
                    show: false,
                },
                // splitNumber: 8,
                axisLabel: {
                    formatter: (value, index) => { return formatNumberIn(value) }
                },
            },
            xAxis: {
                type: 'category',
                data: props.xAxis,
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false,

                    lineStyle: {
                        // color: '#333',
                        width: 1
                    },
                },
                axisLabel: {
                    rotate: 60,
                    margin: 14,
                    formatter: (value, index) => {
                        if (props.xAxisFormat === 'Q')
                            return formatDateAsQuarter(value)
                        else if (props.xAxisFormat === 'Y')
                            return new Date(value).getFullYear()
                        else
                            return value
                    }
                }
            },
            series: series
        })

        setLoading(false);
    }, [props.series])


    useEffect(() => {
        var chart = null;
        if (chartRef.current !== null) {        // Set by first return with no useEffect
            chart = init(chartRef.current);
            loading === true ? chart.showLoading() : chart.hideLoading();
        }

        // Add chart resize listener
        // ResizeObserver is leading to a bit janky UX
        function resizeChart() {
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart);

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
        };
    }, [loading]);                              // Run first to init echarts instance and dispose

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            chart.setOption(options);
        }
    }, [options]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function


    return (
        <Card title={props.title} className='card card-chart' style={cardStyle} extra={extra}>
            <div ref={chartRef} style={{ height: height }} />
        </Card>
    )
}

export default BarChart;
